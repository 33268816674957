import React, { useState, useEffect } from 'react';
import {
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
  IconButton, Tooltip, useTheme, Paper, Box, Typography, TablePagination, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Fab
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';


const NoteDialog = ({ open, handleClose, note, handleSave }) => {
  const [editedNote, setEditedNote] = useState(note);
  useEffect(() => {
    setEditedNote(note);
  }, [note]);

  const handleChange = (e) => {
    setEditedNote({ ...editedNote, [e.target.name]: e.target.value });
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} >
      <DialogTitle id="form-dialog-title">{note ? 'Edit Note' : 'Add Note'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {note ? 'Edit your note details below.' : 'Fill in the details for your new note.'}
        </DialogContentText>
        <TextField
          margin="dense"
          id="notesText"
          name="notesText"
          label="Note Content"
          type="text"
          fullWidth
          variant="filled"
          value={editedNote.notesText}
          onChange={handleChange}
          multiline
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ bgcolor: 'primary.main', color: 'white', '&:hover': { bgcolor: 'primary.dark', } }}>
          Cancel
        </Button>
        <Button onClick={() => handleSave(editedNote)} sx={{ bgcolor: 'secondary.main', color: 'white', '&:hover': { bgcolor: 'secondary.dark', } }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SystemNotesTable = ({notesData, invoiceId ,saveNotes}) => {
  const [notes, setNotes] = useState(notesData||[]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [currentNote, setCurrentNote] = useState({ userId: '', createdDate: '', createdTime: '', idCompany: 1, invoiceId: invoiceId,  invoiceNotesId: null, notesIndicator: 2, notesText: '', });
  const theme = useTheme();

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openDialogForEdit = (note) => {
    setCurrentNote(note);
    setNoteDialogOpen(true);
  };

  const openDialogForAdd = () => {
    setCurrentNote({ userId: '', createdDate: '', createdTime: '', idCompany: 1, invoiceId: invoiceId,  invoiceNotesId: null, notesIndicator: 2, notesText: '', });
    setNoteDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setCurrentNote({ userId: '', createdDate: '', createdTime: '', idCompany: 1, invoiceId: invoiceId, invoiceNotesId: 0, notesIndicator: 2, notesText: '' });
    setNoteDialogOpen(false);
  };

  const handleSaveNote = (editedNote) => {
    saveNotes(editedNote);
    handleCloseDialog();
  };

  return (
    <>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Fab color="primary" aria-label="add" size="small" onClick={openDialogForAdd}> <AddIcon /> </Fab>
      </Box>
      <Paper sx={{ width: '100%', overflow: 'hidden', p: 2, boxShadow: theme.shadows[3] }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="System notes table">
            <TableHead>
              <TableRow sx={{
                '& th': {
                  fontWeight: 'bold',
                  fontSize: '1.1rem',
                  backgroundColor: theme.palette.action.disabledBackground,
                  color: theme.palette.common.black,
                  borderBottom: '1px solid black',
                }
              }}>
                <TableCell align="center">User ID</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Time</TableCell>
                <TableCell align="center">Notes</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((note, index) => (
                <TableRow key={index} hover sx={{
                  '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover },
                  '&:nth-of-type(even)': { backgroundColor: theme.palette.action.selected }
                }}>
                  <TableCell align="center">{note.userId}</TableCell>
                  <TableCell align="center">{note.createdDate}</TableCell>
                  <TableCell align="center">{note.createdTime}</TableCell>
                  <TableCell align="center">{note.notesText}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() => openDialogForEdit(note)}
                        sx={{
                          bgcolor: 'primary.main', color: 'white', '&:hover': { bgcolor: 'primary.dark', }
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {/* Handle delete logic */ }}
                        sx={{ bgcolor: 'error.main', color: 'white', marginLeft: 1, '&:hover': { bgcolor: 'error.dark', } }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ fontWeight: 'bold', fontSize: '1.1rem', backgroundColor: theme.palette.action.disabledBackground, color: theme.palette.common.white }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={notes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              '.MuiTablePagination-toolbar': {
                fontSize: '1.1rem',
              },
              '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                fontSize: '1.1rem',
              },
              '.MuiTablePagination-select, .MuiTablePagination-selectIcon, .MuiMenuItem-root': {
                fontSize: '1.1rem',
              }
            }}
          />

        </Box>
      </Paper>
      <NoteDialog open={noteDialogOpen} handleClose={handleCloseDialog} note={currentNote} handleSave={handleSaveNote} />
    </>
  );
};

export default SystemNotesTable;
