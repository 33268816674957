import * as actionTypes from '../actions/actionTypes';
import moment from 'moment'
const invoiceSelectedDate={
            selectedYear: parseInt(moment().format('YYYY')),
            selectedMonth: parseInt(moment().format('MM')),
            selectedDate: parseInt(moment().format('DD'))
}
export const invoiceDateReducer = (state = invoiceSelectedDate, action) => {
    switch (action.type) {
      case actionTypes.SET_INVOICE_SELECTED_DATE:
        return action.invoiceSelectedDate;
      default:
        return state;
    }
};
const defaultInvSearch={data:[],isSearch:false};
export const invoiceSearchReducer = (state = defaultInvSearch, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_INVOICE_NO:
      return action.searchResult;
      case actionTypes.CLEAR_SEARCH_INVOICE_NO:
      return action.searchResult;
    default:
      return state;
  }
};

const defaultInvoiceMonthYears={}
export const invoiceMonthYearsReducer = (state = defaultInvoiceMonthYears, action) => {
  switch (action.type) {
    case actionTypes.GET_INVOICE_MONTH_YEARS:
      return action.invoiceMonthYears;
    default:
      return state;
  }
};
