import { BASE_URL ,DIS_BASE_URL} from './constants';
class ServiceHTTP {
  // Utility method to choose the correct base URL
  getFullUrl(url, useDispatcher = false) {
    return (useDispatcher ? DIS_BASE_URL : BASE_URL) + url;
  }

  authenticate(userName, password) {
    console.log('base url :', BASE_URL)
    let _serStatusCode = 0;
    let data = JSON.stringify({
      "userId": userName,
      "password": password
    });
    let fullUrl = BASE_URL + 'Login';
    return fetch(fullUrl, {
      method: 'post',
      body: data,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }).then((resp) => {
      _serStatusCode = resp.status;
      return resp.json();
    }).then((response) => {
      response.status = _serStatusCode;
      return response;
    }, (error) => {
      console.log('auth-error :', error);
    })
  }
  getAccessTokenWithRefreshToken = (refreshToken) => {
    let _serStatusCode = 0;
    let fullUrl = BASE_URL + 'oauth/token?grant_type=refresh_token&refresh_token=' + refreshToken;
    return fetch(fullUrl, {
      method: 'post',
      headers: {
        'Authorization': 'Basic ' + btoa('my-trusted-client:secret'),
      }
    }).then((resp) => {
      _serStatusCode = resp.status;
      return resp.json();
    }).then((response) => {
      response.status = _serStatusCode;
      return response;
    }, (error) => {
      console.log('refreshtoken-error :', error);
    })
  }
  
  get(url, headers = { "Accept": "application/json", "Content-Type": "application/json" }, token = undefined) {
    let _serStatusCode = 0;
    if (token) {
      headers.Authorization = 'Bearer ' + token;
    }
    let fullUrl = BASE_URL + url
    return fetch(fullUrl,
      {
        method: "GET",
        headers: headers
      }
    )
      .then((resp) => {
        _serStatusCode = resp.status;
        return resp.json();
      }).then((response) => {
        response.status = _serStatusCode;
        return response;
      }, (error) => {
        console.log('get-error :', error);
      })
  }
  post(url, data, headers = { "Content-Type": "application/json" }, token = undefined) {
    let _serStatusCode = 0;
    if (token) {
      headers.Authorization = 'Bearer ' + token;
    }
    let fullUrl = BASE_URL + url
    return fetch(fullUrl,
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      }
    ).then((resp) => {
      _serStatusCode = resp.status;
      return resp.json();
    }).then((response) => {
      response.status = _serStatusCode;
      return response;
    }, (error) => {
      console.log('post-error :', error);
    })
  }
  postToDispatch(url, data, headers = { "Content-Type": "application/json" }, token = undefined) {
    let _serStatusCode = 0;
    if (token) {
      headers.Authorization = 'Bearer ' + token;
    }
    let fullUrl = DIS_BASE_URL + url
    return fetch(fullUrl,
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      }
    ).then((resp) => {
      _serStatusCode = resp.status;
      return resp.json();
    }).then((response) => {
      response.status = _serStatusCode;
      return response;
    }, (error) => {
      console.log('post-error :', error);
    })
  }
  put(url, data, headers = { "Content-Type": "application/json" }, token = undefined) {
    let _serStatusCode = 0;
    let fullUrl = BASE_URL + url + (token || '') === '' ? '' : '&access_token=' + token;
    return fetch(fullUrl,
      {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(data)
      }
    ).then((resp) => {
      _serStatusCode = resp.status;
      return resp.json();
    }).then((response) => {
      response.status = _serStatusCode;
      return response;
    }, (error) => {
      console.log('put-error :', error);
    })
  }
  delete(url, headers = { "Content-Type": "application/json" }, token = undefined) {
    let _serStatusCode = 0;
    let fullUrl = BASE_URL + url + (token || '') === '' ? '' : '&access_token=' + token;
    return fetch(fullUrl,
      {
        method: 'DELETE',
        headers: headers
      }
    ).then((resp) => {
      _serStatusCode = resp.status;
      return resp.json();
    }).then((response) => {
      response.status = _serStatusCode;
      return response;
    }, (error) => {
      console.log('del-error :', error);
    })
  }

}
export const serviceHTTP = new ServiceHTTP();