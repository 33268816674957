
import moment from 'moment';
class UtilMethods{
    setCookie=(cName, cValue, minutes)=> {
        var d = new Date();
        d.setTime(d.getTime() + (minutes*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires;
    }
    deleteCookie=(cname)=> {
        var d = new Date(); 
        d.setTime(d.getTime() - (1000*60*60*24)); 
        var expires = "expires=" + d.toGMTString(); 
        document.cookie = cname+"="+"; "+expires;     
    }
    enumerateDaysBetweenDates = (startDate, endDate)=> {
        let now = endDate;let dates = [];        
       while (now.isSameOrAfter(startDate)) {
              dates.push(now.format('YYYY/MM/DD'));
              now.subtract(1, 'days');
          }
        return dates;
    };
    getYearByMonthsFromNow=(limit=5)=>{
        let yearMonthsList=[]; 
        let groupYears=[];
        let now=moment().startOf('month');
        let endDate=moment().startOf('year');
        endDate.subtract(limit,"year");        
         while (endDate.isSameOrBefore(now)) {
            groupYears.push({year:now.year(),month:now.format('MMM'),monthNo:now.format('M')});
            now.subtract(1, 'Month');
        }
        yearMonthsList = groupYears.reduce(function (r, a) {
            r[a.year] = r[a.year] || [];
            r[a.year].push(a);
            return r;
        }, Object.create(null)); 
        return yearMonthsList;
    }
    sortInvoiceArray=(invArray)=>{
        let sortedArray=[];
        let _invalidArray=[]
        invArray.map(item=>{
            if(parseInt(item.replace('-','')))
            sortedArray.push({intVal:parseInt(item.replace('-','')),invNo:item});
            else{
                    _invalidArray.push({intVal:'',invNo:item});
            }
        });
        return sortedArray.sort((a,b)=>{
           return a.intVal-b.intVal
        }).concat(_invalidArray.sort((x,y)=>{return x.invNo-y.invNo}));
    }
}
export const utilMethods=new UtilMethods() 