export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_INVOICE_MONTH_YEARS = 'GET_INVOICE_MONTH_YEARS';
export const GET_INVOICE_LIST = 'GET_INVOICE_LIST';
export const SET_INVOICE_SELECTED_DATE = 'SET_INVOICE_SELECTED_DATE';
export const INVOICE_VIEW_TRIGGER = 'INVOICE_VIEW_TRIGGER';

export const SEARCH_INVOICE_NO = 'SEARCH_INVOICE_NO';
export const CLEAR_SEARCH_INVOICE_NO = 'CLEAR_SEARCH_INVOICE_NO';

export const ANY_OF_YOUR_ACTION_TYPES = 'ANY_OF_YOUR_ACTION_TYPES';
export const ANY_OF_YOUR_ACTION_TYPES_FINISH = 'ANY_OF_YOUR_ACTION_TYPES_FINISH';
export const SET_NOTIFY_REF = 'SET_NOTIFY_REF';
export const INVOICESDATA = 'INVOICESDATA';
export const INVOICE_SEARCH_RESULT = 'INVOICE_SEARCH_RESULT';
export const INVOICE_DETAILS = 'INVOICE_DETAILS';
export const INVOICE_NOTES = 'INVOICE_NOTES';
export const RESET_INVOICE_DATA = 'RESET_INVOICE_DATA';


