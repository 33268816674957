import * as actionTypes from '../actions/actionTypes';
import moment from 'moment';

const initialState = {
    originalList: [],
    formattedList: [],
    searchResults: [],
    inprogressInv: [],
    invoicenotes: [],
    invoiceDetails: {}
};

export const invoiceListReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_INVOICE_DATA:
            return {
                ...state,
                originalList: [],
                formattedList: [],
                searchResults: [],
                inprogressInv: [],
                invoicenotes: [],
                invoiceDetails: {}
                // Reset other parts of the state as needed
            };
        case actionTypes.GET_INVOICE_LIST:
            return {
                ...state,
                originalList: action.invoiceList,
                formattedList: formatInvoiceList(action.invoiceList)
            };

        case actionTypes.INVOICE_NOTES:
            return {
                ...state,
                invoicenotes: action.invoicenotes
            };

        case actionTypes.INVOICESDATA:
            return {
                ...state,
                inprogressInv: action.invoices || []
            };

        case actionTypes.INVOICE_DETAILS:
            return {
                ...state,
                invoiceDetails: action.invoiceDetails || {}
            };

        case actionTypes.INVOICE_SEARCH_RESULT:
            return {
                ...state,
                searchResults: action.invoiceSearchData || []
            };

        default:
            return state;
    }
};

const formatInvoiceList = (list) => {
    return list.map(item => {
        return {
            createdDate: moment(item.createdDate).format('YYYY-MM-DD'), // format as needed
            accountId: item.accountId,
            attachments: item.attachments
        };
    });
};
