import * as actionTypes from './actionTypes';
import { serviceHTTP } from '../util/service';
import { store } from '../store/configureStore';
import * as spinnerActions from '../actions/spinnerActions';
import { getAccessToken, clearSessionData } from '../util/customMethods';
export const authenticateUser = (userName, password, success, fail) => {
  return (dispatch) => {
    dispatch(spinnerActions.showSpinner());
    return serviceHTTP.authenticate(userName, password)
      .then(response => {
        if (response.error === undefined) {
          response.isAuthenticated = true;
          response.userId = userName;
          dispatch(
            {
              type: actionTypes.AUTHENTICATE_USER,
              authInfo: response
            });

          dispatch(spinnerActions.hideSpinner());
          success();
        } else {
          response.isAuthenticated = false;
          response.userId = '';
          dispatch(
            {
              type: actionTypes.AUTHENTICATE_USER,
              authInfo: response
            });
          dispatch(spinnerActions.hideSpinner());
          fail();
        }
      })
      .catch(error => {
        dispatch(spinnerActions.hideSpinner());
        throw (error);
      });
  };
};
export const resetPassword = (userName, newPwd, secToken, success, fail) => {
  return (dispatch) => {
    dispatch(spinnerActions.showSpinner());
    return serviceHTTP.post('resetPassword', {
      "newPassword": newPwd,
      "userId": userName,
      "forgetToken": secToken,
    }, undefined, secToken)
      .then(response => {
        dispatch(spinnerActions.hideSpinner());
        success(response);
      })
      .catch(error => {
        dispatch(spinnerActions.hideSpinner());
        fail(error);
        throw (error);
      });
  }
}
export const forgetPassword = (userName, success, fail) => {
  return (dispatch) => {
    dispatch(spinnerActions.showSpinner());
    return serviceHTTP.post('recoverVendorUserPassword', {
      "emailId": userName,
      "forgetOption": "forgetPass"
    }, undefined, undefined)
      .then(response => {
        dispatch(spinnerActions.hideSpinner());
        success(response);
      })
      .catch(error => {
        dispatch(spinnerActions.hideSpinner());
        fail(error);
        throw (error);
      });
  }
}
export const isAuthenticated = () => {
  if (Object.keys((store.getState()).authInfo).length > 0) {
    return (store.getState()).authInfo.isAuthenticated
  } else {
    return false;
  }

}
export const logoutUser = (success, fail) => {
  let url = 'userLogout?access_token=' + getAccessToken()
  return (dispatch) => {
    dispatch(spinnerActions.showSpinner());
    return serviceHTTP.get(url, undefined, undefined)
      .then(response => {
        dispatch(spinnerActions.hideSpinner());
        dispatch(clearSessionData());
        success();
      })
      .catch(error => {
        dispatch(spinnerActions.hideSpinner());
        dispatch(clearSessionData());
        fail(error);
        throw (error);
      });
  }
}