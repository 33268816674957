import React from 'react';
import Lightbox from 'react-images';

class ImageView extends React.Component {    
    render() {
      return (
        <Lightbox
          images={[{ src: this.props.imagePath }]}
          isOpen={this.props.lightboxIsOpen}
          onClose={()=>{this.props.closeLightbox()}}
        />
      );
    }
  }
  export default ImageView