import React, { useState, useEffect,useRef } from 'react';
import {
    Box, TextField, Button, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Card, IconButton,
    Typography, Select, MenuItem, FormControl, InputLabel,
    useMediaQuery, Paper
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { TablePagination } from '@mui/material';
import * as searchInvoiceMetaData from '../../actions/invoiceDateActions'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";

const theme = createTheme({
    components: {
        // Name of the component
        MuiTableCell: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    borderBottom: 'none', // remove the border
                },
            },
        },
    },
});

// Custom styling for table header cells
const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold, // Use theme's bold font weight
    fontSize: '1rem', // Increase font size, adjust as needed
    color: theme.palette.common.black,
    backgroundColor: theme.palette.action.hover,
    // Remove borders if necessary
    borderBottom: 'none',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.disabledBackground, // Alternate row color
        border: 'none' // Remove border
    },
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        border: 'none' // Remove border
    },
    '&:hover': {
        backgroundColor: theme.palette.action.selected, // More noticeable hover effect
        cursor: 'pointer', // Change cursor to indicate clickable area
        // Adding a transition for smoother hover effect
        transition: 'background-color 0.3s',
        border: 'none', // Remove border

        // Change text color of cells on hover
        '& .MuiTableCell-body': {
            color: theme.palette.primary.main, // Change text color to primary color of theme
            textDecoration: 'underline', // Optional: underline on hover
        }
    },
    '& .MuiTableCell-body': {
        fontSize: '11px',
        border: 'none', // Remove border
        // Optional: transition effect for color change
        transition: 'color 0.3s',
    },
    border: 'none', // Remove border
}));

const InvoiceSearchPage = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    // Ref for the date pickers
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);
     // Function to handle criteria change
     const handleCriteriaChange = (event) => {
        const newCriteria = event.target.value;
        setSearchParams({
            ...searchParams,
            criteria: newCriteria,
            searchValue: newCriteria ? searchParams.searchValue : '' // Reset searchValue if criteria is deselected
        });
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page after changing rows per page
    };

    const { getInvoicesData, authInfo, searchResults, history, resetData } = props;
    const [searchParams, setSearchParams] = useState({
        startDate: '',
        endDate: '',
        criteria: '',
        searchValue: ''
    });
    const handleInputChange = (event) => {
        setSearchParams({ ...searchParams, [event.target.name]: event.target.value });
    };

    const handleSearch = () => {
        // Dispatch the action to fetch invoices
        getInvoicesData(searchParams);
    };

    // Reset invoice data when component mounts or searchParams change
    useEffect(() => {
        resetData();
    }, [resetData]); // Dependencies array
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ padding: 2 }}>
                <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Invoice Search
                </Typography>
                <Box component="form" sx={{ display: 'flex', gap: 2, marginBottom: 3, alignItems: 'flex-end' }}>
                <TextField
                        variant='filled'
                        label="Start Date"
                        type="date"
                        name="startDate"
                        value={searchParams.startDate}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                        onClick={() => startDateRef.current.focus()} // Focus on the input on click
                        inputRef={startDateRef} // Attach the ref
                    />
                    <TextField
                        variant='filled'
                        label="End Date"
                        type="date"
                        name="endDate"
                        value={searchParams.endDate}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                        onClick={() => endDateRef.current.focus()} // Focus on the input on click
                        inputRef={endDateRef} // Attach the ref
                    />
                    <FormControl variant="filled" sx={{ minWidth: 120 }}>
                        <InputLabel>Criteria</InputLabel>
                        <Select
                            label="Criteria"
                            name="criteria"
                            value={searchParams.criteria}
                            onChange={handleCriteriaChange}
                        >
                            <MenuItem value="">None</MenuItem> {/* Allow deselecting criteria */}
                            <MenuItem value="invoice">Invoice</MenuItem>
                            <MenuItem value="vin">VIN</MenuItem>
                            <MenuItem value="plate">Plate</MenuItem>
                            <MenuItem value="or_dr">OR/DR</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        variant='filled'
                        label="Search"
                        name="searchValue"
                        value={searchParams.searchValue}
                        onChange={handleInputChange}
                    />
                    <Button variant="contained" endIcon={<SearchIcon />} onClick={handleSearch}>
                        Search
                    </Button>
                </Box>


                <Card variant="outlined" sx={{ borderRadius: 0, boxShadow: 0 }}>
                    <TableContainer component={Paper}>
                        <Table stickyHeader sx={{ minWidth: 750, border: 'none' }}>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableHeaderCell>Invoice</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>Reason</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>Date</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>OR/DR</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>Year</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>Make</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>Model</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>Color</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>Plate</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>VIN</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>View</StyledTableHeaderCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((invoice) => (
                                    <StyledTableRow key={invoice.id}
                                        onClick={() => window.open(`/invoice/${invoice.invoiceId}`, '_blank')}>
                                        <TableCell>{invoice.invoiceId}</TableCell>
                                        <TableCell>{invoice.reason}</TableCell>
                                        <TableCell>{invoice.invoiceCreatedDate}</TableCell>
                                        <TableCell>{invoice.vehicle_OR_DR_No}</TableCell>
                                        <TableCell>{invoice.vehicle_Year}</TableCell>
                                        <TableCell>{invoice.vehicle_Make}</TableCell>
                                        <TableCell>{invoice.vehicle_Model}</TableCell>
                                        <TableCell>{invoice.vehicle_Color}</TableCell>
                                        <TableCell>{invoice.vehicle_Plate_No}</TableCell>
                                        <TableCell>{invoice.vehicle_VIN}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={(e) => {
                                                e.stopPropagation(); // Prevent row click event
                                                window.open(`/invoice/${invoice.invoiceId}`, '_blank') // Navigate on button click
                                            }}>
                                                <VisibilityIcon color="primary" />
                                            </IconButton >
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={searchResults.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>

            </Box>
        </ThemeProvider>
    );
};

const mapStateToProps = (state) => ({
    searchResults: (state.invoiceList && state.invoiceList.searchResults) || [],
    authInfo: state.authInfo,
});
const mapDispatchToProps = (dispatch) => ({
    getInvoicesData: (searchCriteria) => {
        dispatch(searchInvoiceMetaData.searchInvoiceMetaData(searchCriteria));
    },
    resetData: () => {
        dispatch(searchInvoiceMetaData.resetInvoiceData());
    }
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceSearchPage));
