import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as invoiceDateActions from '../../actions/invoiceDateActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPermissionByName } from '../../util/customMethods'; // Ensure correct path
import { faDashboard, faMap, faSearch, faFileAlt, faCalendarAlt, faSignOutAlt, faChartBar } from '@fortawesome/free-solid-svg-icons'; // Import the icon for ARS Usage Report

class LeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      towSheetsCollapsed: true,
      reportsCollapsed: true,
      expandedYear: null,
    };
  }

  toggleReports = () => {
    this.setState(prevState => ({
      reportsCollapsed: !prevState.reportsCollapsed
    }));
  }

  componentWillMount = () => {
    this.props.getInvoiceMonthYears(this.props.authInfo.userId);
  }

  toggleTowSheets = () => {
    this.setState(prevState => ({
      towSheetsCollapsed: !prevState.towSheetsCollapsed
    }));
  }

  toggleYear = (year) => {
    this.setState(prevState => ({
      expandedYear: prevState.expandedYear === year ? null : year
    }));
  }

  render() {
    const { invoiceMonthYears, invoiceSelectedDate, authInfo } = this.props;
    const { towSheetsCollapsed, expandedYear, reportsCollapsed } = this.state;

    const userPermissions = authInfo.permissions || []; // Assuming permissions are stored in authInfo.permissions

    return (
      <aside className="aside">
        <nav className="sidebar">
          <ul className="nav">
            {getPermissionByName('dashboard') && (
              <li>
                <Link to="/dashboard">
                  <FontAwesomeIcon icon={faDashboard} style={{ marginRight: '5px' }}/>
                  <span className="item-text">Dashboard</span>
                </Link>
              </li>
            )}
            {getPermissionByName('map') && (
              <li>
                <Link to="/map">
                  <FontAwesomeIcon icon={faMap} style={{ marginRight: '5px' }}/>
                  <span className="item-text">Map</span>
                </Link>
              </li>
            )}
            {getPermissionByName('search') && (
              <li>
                <Link to="/search">
                  <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }}/>
                  <span className="item-text">Search</span>
                </Link>
              </li>
            )}
            {getPermissionByName('reports') && (
              <li>
                <a href="#reports" onClick={this.toggleReports}>
                  <FontAwesomeIcon icon={faChartBar} style={{ marginRight: '5px' }}/>
                  <span className="item-text">Reports</span>
                </a>
                <ul className={reportsCollapsed ? 'nav collapse' : 'nav collapse in'}>
                  {getPermissionByName('reports') && (
                    <li>
                      <Link to="/ars-usage-report">
                        <FontAwesomeIcon icon={faChartBar} style={{ marginRight: '5px' }} />
                        <span className="item-text">ARS Usage Report</span>
                      </Link>
                    </li>
                  )}
                  {/* Additional report options can be added here */}
                </ul>
              </li>
            )}
            {getPermissionByName('towSheets') && (
              <li>
                <a href="#towSheets" onClick={this.toggleTowSheets}>
                  <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faFileAlt} />
                  <span className="item-text">Tow Sheets</span>
                </a>
                <ul className={towSheetsCollapsed ? 'nav collapse' : 'nav collapse in'}>
                  {Object.keys(invoiceMonthYears).sort((a, b) => b - a).map(year => (
                    <React.Fragment key={year}>
                      <li className={expandedYear === year ? 'active' : null}>
                        <a href="#year" onClick={(e) => {
                          e.preventDefault();
                          this.toggleYear(year);
                        }}>
                          <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: '5px' }}/>
                          <span className="item-text">{year}</span>
                        </a>
                      </li>
                      {expandedYear === year && invoiceMonthYears[year].map((month, index) => (
                        <li key={index} className={invoiceSelectedDate.selectedMonth === parseInt(month.monthNo) ? 'active' : null}>
                          <a href="javascript:void(0);" onClick={(e) => {
                            e.preventDefault();
                            this.props.setInvoiceSelectedDate(parseInt(year), parseInt(month.monthNo), 0);
                            this.props.getInvoiceList(authInfo.userId, parseInt(year), parseInt(month.monthNo));
                            this.props.history.push('/home');
                          }}>
                            <span className="item-text">{month.month}</span>
                          </a>
                        </li>
                      ))}
                    </React.Fragment>
                  ))}
                </ul>
              </li>
            )}
            <li className="nav-footer">
              <div className="btn-group text-center">
                <button type="button" data-toggle="tooltip" data-title="Logout" className="btn btn-link" onClick={this.logout}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </button>
              </div>
            </li>
          </ul>
        </nav>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoiceSelectedDate: state.invoiceSelectedDate,
    authInfo: state.authInfo,
    invoiceMonthYears: state.invoiceMonthYears
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setInvoiceSelectedDate: (_year, _month, _date) => {
      document.getElementById('txtSearch').value = '';
      document.getElementById('search-pan').classList.remove('open');
      dispatch(invoiceDateActions.setInvoiceSelectedDate(_year, _month, _date));
    },
    getInvoiceList: (user, _year, _month) => {
      document.getElementById('txtSearch').value = '';
      document.getElementById('search-pan').classList.remove('open');
      dispatch(invoiceDateActions.getInvoiceList(user, _year, _month));
    },
    getInvoiceMonthYears: (user) => {
      dispatch(invoiceDateActions.getInvoiceMonthYears(user));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftMenu));
