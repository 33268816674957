import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as bootstrap from 'react-bootstrap/dist/react-bootstrap';
import ResetPassword from '../../components/authComponents/resetPassword';
class ReactPwd extends Component {
   
    render() {
      return (<>
        <div style={{height: "100%", padding: "50px 0"}} className="row row-table">
            <ResetPassword />
   </div>
          </>
      )
    }
}
export default ReactPwd;