import React, { Component } from 'react';
import { Link,withRouter } from "react-router-dom";
import queryString from 'query-string';
import { connect } from 'react-redux';
import InvoiceAttachmentsView from '../../components/contentView/invoiceAttachmentsView';
import * as bootstrap from 'react-bootstrap/dist/react-bootstrap';
import {utilMethods} from '../../util/util';
import moment from 'moment';
class InvoiceAttachments extends Component {
    constructor(props){
        super(props);
        this.state={
            invoiceId:'',
            invDate:''
        }
    }
    componentWillMount=()=>{
        const value=queryString.parse(this.props.location.search);
        this.setState({invoiceId:value.id,invDate:value.date})
    }
render(){
    return(<section>

        <section className="main-content">
        <h3><i className="fa fa-paperclip"></i> Attachments {'['+this.state.invoiceId+']'}</h3>
        <div className="row">
        <InvoiceAttachmentsView />
        </div>

        <button className="btn btn-info pull-right" onClick={()=>{
            this.props.history.push('/home')
        }} ><i className="fa fa-long-arrow-left"></i> Back</button>
    </section></section>)
}
}
const mapStateToProps = (state) => {
    return {
       invoiceSelectedDate: state.invoiceSelectedDate
    }
  };
  export default withRouter(connect(mapStateToProps)(InvoiceAttachments));