import React, { Component } from 'react';
import * as bootstrap from 'react-bootstrap/dist/react-bootstrap';
import { utilMethods } from '../../util/util.js'
import moment from 'moment';
import './invoiceView.css';
class InvoiceThumbView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '1'
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.bindDateGroups = this.bindDateGroups.bind(this);
    this.bindInvoices = this.bindInvoices.bind(this);
  }
  handleSelect(activeKey) {
    this.setState({ activeKey });
  }
  bindDateGroups = () => {
    let _noInvoiceDatesCount = 0;
    let _datesDom = this.props.invoiceList.map((item, key) => {
      if (Object.keys(item.attachments || {}).length > 0) {
        return (
          <bootstrap.Panel key={'m' + (key + 1).toString()} eventKey={(key + 1).toString()} className="cardsmall">
            <bootstrap.Panel.Heading className="cardsmall-header">
              <bootstrap.Panel.Title toggle>{moment(item.createdDate).format('YYYY/MM/DD')}</bootstrap.Panel.Title>
            </bootstrap.Panel.Heading>
            <bootstrap.Panel.Body collapsible className="panel-body">
              <div className="row list-icon">
                {
                  this.bindInvoices(item.attachments, moment(item.createdDate))
                }
              </div>
            </bootstrap.Panel.Body>
          </bootstrap.Panel>
        );
      } else {
        _noInvoiceDatesCount++
      }
    });

    if (this.props.invoiceList.length === _noInvoiceDatesCount) {
      return (<div>No Invoices data exists for this month</div>)
    } else {
      return _datesDom;
    }
  }
  bindInvoices(invoiceList, cDate) {
    let _invList = Object.keys(invoiceList);
    let sortedList = utilMethods.sortInvoiceArray(_invList);
    return sortedList.map((item, key) => {
      return (<div key={(key + 1).toString()} className="col-md-3 col-sm-4">
        <div className="inv-thumb">
          <bootstrap.Image src={require('../../assets/img/invoice.png')} rounded />
          {invoiceList[item.invNo].length > 0 ? <a href="javascript:void(0)" onClick={() => {
            if (invoiceList[item.invNo].length > 0) {
              window.open((invoiceList[item.invNo])[0].attachmentURL);
            }
          }}><span>{item.invNo}</span></a> : <label>{item.invNo}</label>

          }

        </div>
      </div>)
    })

  }

  render() {
    return (<>
      <div className="row">
        <div className="col-lg-12">
          <bootstrap.PanelGroup
            accordion
            id="accordion-invoice-thumb-view"
            activeKey={this.state.activeKey}
            onSelect={this.handleSelect}
          >{
              this.bindDateGroups()
            }
          </bootstrap.PanelGroup>
        </div></div></>);
  }
}

export default InvoiceThumbView;