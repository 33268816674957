import React, { Component,Fragment } from 'react';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import * as authenticateActions from '../../actions/authenticateActions';
import {notify} from '../../util/notifications';
import { FormValidation } from "calidation";
import queryString from 'query-string'

class ResetPassword extends Component { 
    constructor(){
        super();
        this.state={
            userName:'',
            password:'',
            confPassword:''
        }
        this.onChangeValues=this.onChangeValues.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }
    onChangeValues=(event)=>{
        let _state=this.state;
        _state[event.currentTarget.name]=event.currentTarget.value;
        this.setState(_state);
    }
    handleSubmit=(fields, errors, isValid)=> {
        const value=queryString.parse(this.props.location.search);
        if (fields.isValid) {
            const { dispatch,history } = this.props;
            dispatch( authenticateActions.resetPassword(value.userId,fields.fields.password,value.token,(success)=>{
                notify.createNotification('success','Changed new password successfully.',this.props.refNotify);
                history.push('/');
            },(error)=>{
                notify.createNotification('error','Something went wrong. Please contact administrator',this.props.refNotify);
            }));

         }else{

         }
    }
    render(){
        const config = {
            password: {
              isRequired: "Password field required!"
            },
            confPassword: {
                isRequired: 'Please fill out the password a second time',
                isEqual: ({ fields }) => ({
                    message: 'The two password must match',
                    value: fields.password,
                    validateIf: fields.password.length > 0, // this can be a boolean too!
                }),
            }
          };
        return(
            <div className="col-lg-3 col-md-6 col-sm-8 col-xs-12 align-middle">
        <div className="panel panel-default">
            <p className="text-center mb-lg">
            <br />
            <a href="#">
                <img src={require('../../assets/img/logo-2-white.png')} style={{height:'70px'}} alt="ACT" className="block-center img-rounded" />
            </a>
            </p>
            <p className="text-center mb-lg">
            <strong>PASSWORD RESET</strong>
            </p>
            <div className="panel-body">
            <FormValidation name="frmReset" className="mb-lg" config={config}
                     onSubmit={  this.handleSubmit
                    } >
                    {({ fields, errors, submitted }) => (
                    <Fragment>                         
                           <div className={(submitted && errors.password)?"form-group has-feedback has-error has-danger":"form-group has-feedback"}>
                              <label for="txtPassword">Password</label>
                              <input id="txtPassword" name="password" type="password" placeholder="Password" className="form-control" value={fields.password}
                              onChange={this.onChangeValues} />
                              <span className="fa fa-lock form-control-feedback text-muted"></span>
                              {(submitted && errors.password) ? 
                                <div className="invalid-feedback with-errors" >
                                <span className="text-danger">{errors.password}</span>
                                </div>
                                : null}
                           </div>
                           <div className={(submitted && errors.confPassword)?"form-group has-feedback has-error has-danger":"form-group has-feedback"}>
                              <label for="confPassword">ReType Password</label>
                              <input id="confPassword" name="confPassword" type="password" placeholder="ReType Password" className="form-control" value={fields.confPassword}
                              onChange={this.onChangeValues} />
                              <span class="fa fa-lock form-control-feedback text-muted"></span>
                              {(submitted && errors.confPassword) ? 
                                <div className="invalid-feedback with-errors" >
                                <span className="text-danger">{errors.confPassword}</span>
                                </div>
                                : null}
                           </div>
                           <div className="clearfix">                              
                           </div>
                           <button type="submit" className="btn btn-block btn-primary">Submit</button>
                           </Fragment>
                    )}
         </FormValidation>

            </div>
            </div>
        
        </div>)
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
      authInfo: state.authInfo,
      refNotify:state.refNotify
    }
  };
 
export default withRouter(connect(mapStateToProps)(ResetPassword))