import React, { useState, useEffect } from 'react';
import { Link, withRouter, useParams } from 'react-router-dom';
import { Grid, Paper, Tabs, Tab, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import InvoiceIcon from '@mui/icons-material/Receipt';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import NoteIcon from '@mui/icons-material/Note';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { connect } from 'react-redux';
import * as searchInvoiceMetaData from '../../actions/invoiceDateActions';
import SystemNotesTable from './SystemNotesTable'; // Adjust the path accordingly

const VendorInvoiceView = (props) => {
    const { fetchInvoice, invoiceDetails,saveNotes,invoicenotes, match } = props;
    const invoiceId = match.params.invoiceId; // Get the invoice ID from the UR
    const [tab, setTab] = useState(0);

    useEffect(() => {
        if (invoiceId) {
            fetchInvoice(invoiceId)
        }
    }, [invoiceId, fetchInvoice]);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    const systemNotes = [
        { userId: 'ADMIN', date: '01/01/2024', time: '23:21:48', content: 'INVOICE ACCESSED BY ADMIN' },
        { userId: 'GYOST', date: '12/26/2023', time: '13:05:01', content: 'INVOICE SAVED BY GYOST' },
    ];
    return (
        <Grid>
            <Tabs
                variant='standard'
                value={tab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label="Invoice View" />
                <Tab label="Notes" />
            </Tabs>
            {tab === 0 && (
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <List>
                            <ListItem>
                                <ListItemIcon><InvoiceIcon /></ListItemIcon>
                                <ListItemText primary="Invoice #" secondary={invoiceDetails.invoiceId} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><DateRangeIcon /></ListItemIcon>
                                <ListItemText primary="Created Date" secondary={invoiceDetails.invoiceCreatedDate} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                                <ListItemText primary="Account Name" secondary={invoiceDetails.name} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><InfoIcon /></ListItemIcon>
                                <ListItemText primary="Reason" secondary={invoiceDetails.reason} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><CallIcon /></ListItemIcon>
                                <ListItemText primary="Caller Name" secondary={invoiceDetails.callerName} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><LocationOnIcon /></ListItemIcon>
                                <ListItemText primary="Pickup Info" secondary={invoiceDetails.pickUpAddress} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                                <ListItemText primary="Drop Info Time" secondary={invoiceDetails.dropOffAddress} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>

                        </List>
                    </Grid>
                    <Grid item xs={4}>
                        <List>
                            <ListItem>
                                <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                                <ListItemText primary="Request Time" secondary={invoiceDetails.requestTime} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                                <ListItemText primary="Dispatch Time" secondary={invoiceDetails.dispatchTime} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                                <ListItemText primary="Accepted Time" secondary={invoiceDetails.acceptedTime} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                                <ListItemText primary="Enroute Time" secondary={invoiceDetails.enrouteTime} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                                <ListItemText primary="Arrived Time" secondary={invoiceDetails.arrivedTime} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                                <ListItemText primary="Hooked Time" secondary={invoiceDetails.hookedTime} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>

                        </List>
                    </Grid>
                    <Grid item xs={4}>
                        <List>
                            <ListItem>
                                <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                                <ListItemText primary="Drop Off Time" secondary={invoiceDetails.dropOffTime} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>

                            <ListItem>
                                <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                                <ListItemText primary="Cleared Time" secondary={invoiceDetails.clearedTime} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><DirectionsCarIcon /></ListItemIcon>
                                <ListItemText primary="VIN" secondary={invoiceDetails.vehicle_VIN} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><VpnKeyIcon /></ListItemIcon>
                                <ListItemText primary="Plate" secondary={invoiceDetails.vehicle_Plate_No} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><DirectionsCarIcon /></ListItemIcon>
                                <ListItemText primary="Year/Make/Model" secondary={`${invoiceDetails.vehicle_Year} ${invoiceDetails.make} ${invoiceDetails.model}`} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><NoteIcon /></ListItemIcon>
                                <ListItemText primary="OR/DR" secondary={invoiceDetails.vehicle_OR_DR_No} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><ColorLensIcon /></ListItemIcon>
                                <ListItemText primary="Color" secondary={invoiceDetails.vehicle_Color} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            )}
            {tab === 1 && (
                <Paper sx={{ width: '100%', overflow: 'hidden', p: 2 }}>
                    {/* Render the SystemNotesTable inside the second tab */}
                    <SystemNotesTable notesData={invoicenotes} invoiceId={invoiceId} saveNotes={(obj)=>{saveNotes(obj,invoiceId)}}/>
                </Paper>
            )}

        </Grid>
    );
};
const mapStateToProps = (state) => ({
    invoiceDetails: (state.invoiceList && state.invoiceList.invoiceDetails) || {},
    invoicenotes:(state.invoiceList && state.invoiceList.invoicenotes) || [],
    authInfo: state.authInfo,
});
const mapDispatchToProps = (dispatch) => ({
    fetchInvoice: (id) => {
        dispatch(searchInvoiceMetaData.fetchInvoice(id));
        dispatch(searchInvoiceMetaData.fetchInvoiceNotes(id));
    },
    saveNotes:(obj,id)=>{
        dispatch(searchInvoiceMetaData.saveNotes(obj,id));
    }
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VendorInvoiceView));