import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import { Link,withRouter } from "react-router-dom";
import * as bootstrap from 'react-bootstrap/dist/react-bootstrap';
import {utilMethods} from '../../util/util.js'
import moment from 'moment';
import './invoiceView.css';
const invoicePath='../../assets/img/invoice.png';
class InvoiceTableView extends Component {
  constructor(props){
    super(props);
    this.state = {
        activeKey: '1'        
      };
    this.handleSelect = this.handleSelect.bind(this);
    this.bindDateGroups=this.bindDateGroups.bind(this);
    this.bindInvoices=this.bindInvoices.bind(this);
}
handleSelect(activeKey) {
    this.setState({ activeKey });
  }
  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };
  getEndDate=(endDate)=>{
    let now=moment();
    if(endDate<=now){
      if(endDate.year()===now.year() && endDate.month()===now.month()){
        return now;
      }else{
        return endDate;
      }
    }
    else{
      return now;
    }
  }
  bindDateGroups=()=>{    
    let _noInvoiceDatesCount=0;
    let _datesDom=this.props.invoiceList.map((item,key) => {
      if(Object.keys(item.attachments).length>0){
        return(
          <bootstrap.Panel eventKey={(key+1).toString()} className="cardsmall">
            <bootstrap.Panel.Heading className="cardsmall-header">
              <bootstrap.Panel.Title toggle>{moment(item.createdDate).format('YYYY/MM/DD')}</bootstrap.Panel.Title>
            </bootstrap.Panel.Heading>
            <bootstrap.Panel.Body  collapsible className="panel-body">
              <div className="row list-icon"> 
              <bootstrap.Table className="table table-striped table-hover dataTable no-footer inv-view-table">
              <tbody>               
                  {
                    this.bindInvoices(item.attachments,moment(item.createdDate))
                  }</tbody>
                  </bootstrap.Table>
                </div>
            </bootstrap.Panel.Body>
          </bootstrap.Panel>
        );
      }else {
        _noInvoiceDatesCount++
      }
    });

    if(this.props.invoiceList.length===_noInvoiceDatesCount){
      return (<bootstrap.Table className="table table-striped table-hover dataTable no-footer inv-view-table">
      <tfoot><tr><td colSpan="4">No Invoices data exists for this month</td></tr></tfoot></bootstrap.Table>)
    }else{
     return _datesDom;
    }
  }
  bindInvoices(invoiceList,cDate){
    let _invList=Object.keys(invoiceList);
    let sortedList=utilMethods.sortInvoiceArray(_invList);
   return sortedList.map(item=>{
      return( <tr>
        <td className="image-col"><bootstrap.Image src={require('../../assets/img/invoice.png')  } height='15px' rounded /></td>
        <td> { invoiceList[item.invNo].length>0?<a href="javascript:void(0)" onClick={()=>{
          if(invoiceList[item.invNo].length>0){
            window.open((invoiceList[item.invNo])[0].attachmentURL);
          }
        }}><span>{item.invNo}</span></a>:<label>{item.invNo}</label>

        } </td>
        <td></td>
        <td> </td>
      </tr> 
     )
    })

  }
  
    render() {
      return (<Fragment>
      <div className="row">
      <div className="col-lg-12">
        <bootstrap.Table className="table table-striped table-hover dataTable no-footer inv-view-table">
          <thead>
            <tr>
              <th  className="image-col">#</th>
              <th>Invocie No</th>
              <th></th>
              <th> </th>
            </tr>
          </thead>
          </bootstrap.Table>
        <bootstrap.PanelGroup
          accordion
          id="accordion-invoice-thumb-view"
          activeKey={this.state.activeKey}
          onSelect={this.handleSelect}
        >
          {
            this.bindDateGroups()                
          }
        </bootstrap.PanelGroup>
        </div>
        </div>
        
        </Fragment>
      );
    }
}
export default withRouter(InvoiceTableView);