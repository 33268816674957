import React, { Component,Fragment } from 'react';
import { withRouter,Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as authenticateActions from '../../actions/authenticateActions';
import {notify} from '../../util/notifications';
import { FormValidation } from "calidation";
class Login extends Component { 
    constructor(){
        super();
        this.state={
            userName:'',
            password:''
        }
        this.onChangeValues=this.onChangeValues.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }    
    onChangeValues=(event)=>{
        let _state=this.state;
        _state[event.currentTarget.name]=event.currentTarget.value;
        this.setState(_state
        );
    }  
    handleSubmit=(fields, errors, isValid)=> {
        if (fields.isValid) {       
        const { dispatch,history } = this.props;
        dispatch( authenticateActions.authenticateUser(fields.fields.userName,fields.fields.password,(success)=>{
            history.push('/');
        },(error)=>{
            notify.createNotification('error','Invalid UserName/Password',this.props.refNotify);
        }));
    }else{
        console.log("Please fill all the required fields", errors);
    }
      }
    render(){
        const config = {
            userName: {
              isRequired: "User name is required!",
              isEmail: "Valid emails only, please&ty"
            },
            password: {
              isRequired: "Password field required!"
            }
          };
      return(<Fragment>
           <div className="col-lg-3 col-md-6 col-sm-8 col-xs-12 align-middle">
        <div className="panel panel-default">
            <p className="text-center mb-lg">
            <br />
            <a href="#">
                <img src={require('../../assets/img/logo-2-white.png')} style={{height:'70px'}} alt="ACT" className="block-center img-rounded" />
            </a>
            </p>
            <p className="text-center mb-lg">
            <strong>SIGN IN TO CONTINUE.</strong>
            </p>
            <div className="panel-body">                   
                    <FormValidation name="frmLogin" className="mb-lg" config={config}
                     onSubmit={  this.handleSubmit
                    } >
                    {({ fields, errors, submitted }) => (
                    <Fragment>
                      <div className={(submitted && errors.userName)?"form-group has-feedback has-error has-danger":"form-group has-feedback"}>
                        <input id="userName" name="userName" type="text" placeholder="Enter User name" 
                        className="form-control" value={fields.userName} onChange={(e)=>{
                            this.onChangeValues(e);
                        }} />
                        <span className="fa fa-envelope form-control-feedback text-muted"></span>
                        {(submitted && errors.userName) ? 
                        <div className="invalid-feedback with-errors" >
                        <span className="text-danger">{errors.userName}</span>
                        </div>
                        : null}</div>
                    <div className={(submitted && errors.password)?"form-group has-feedback has-error has-danger":"form-group has-feedback"}>
                        <input id="password" name="password" type="password" placeholder="Password" 
                        className="form-control" value={fields.password} onChange={(e)=>{
                            this.onChangeValues(e);
                        }} />
                        <span className="fa fa-lock form-control-feedback text-muted"></span>
                        {(submitted && errors.password) ? <div className="invalid-feedback with-errors" >
                        <span className="text-danger">{errors.password}</span></div>:null}
                    </div>
                    <div className="clearfix">
                        <div className="checkbox c-checkbox pull-left mt0">
                            <label>
                            <input type="checkbox" value="" />
                            <span className="fa fa-check"></span>Remember Me</label>
                        </div>
                        <div className="pull-right"><Link to="/forgetPwd" className="text-muted">Forgot your password?</Link>
                        </div>
                    </div> 
                    <button type="submit" className="btn btn-block btn-primary" >Login </button> 
          </Fragment>
                    )}
         </FormValidation>
    </div>
    </div>
 </div>
 </Fragment>)
    }

}
const mapStateToProps = (state, ownProps) => {
    return {
      authInfo: state.authInfo,
      refNotify:state.refNotify
    }
  };
 
export default withRouter(connect(mapStateToProps)(Login))