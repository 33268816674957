import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, useFilters, usePagination } from 'react-table';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Box, Modal, Typography, useTheme, Backdrop, Fade, IconButton, Select, MenuItem } from '@mui/material';
import * as invoiceDateActions from '../../actions/invoiceDateActions';
import { Link, withRouter } from "react-router-dom";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
const DefaultColumnFilter = ({
  column: { filterValue, setFilter },
}) => {
  return (
    <TextField
      value={filterValue || ''}
      onChange={(e) => setFilter(e.target.value || undefined)}
      placeholder="Search..."
      size="small"
      variant="filled"
      fullWidth
      InputProps={{
        style: {
          fontWeight: 'bold',
          paddingTop: '5px', // Reduced top padding
          paddingBottom: '5px', // Reduced bottom padding
          lineHeight: '1', // Adjust line height to center vertically
        },
      }}
      // Adjust the styles to center the placeholder and enhance aesthetics
      sx={{
        '& .MuiInputBase-input': {
          textAlign: 'center',
          paddingTop: '5px', // Reduced top padding
          paddingBottom: '5px', // Reduced bottom padding
        },
        '& .MuiFilledInput-root': {
          backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slightly transparent white
          borderRadius: '4px', // Adjust if needed
          '&:after': { // Remove underline on focused state
            borderBottom: 'none',
          },
          '&:before': { // Remove underline in default state
            borderBottom: 'none',
          },
          '&:hover:before': { // Remove underline on hover
            borderBottom: 'none',
          },
          height: '35px', // Adjust the height of the TextField
        },
        '& .MuiFilledInput-underline:before': { // Remove underline in default state
          borderBottom: 'none',
        },
        '& .MuiFilledInput-underline:hover:before': { // Remove underline on hover
          borderBottom: 'none',
        },
      }}
    />
  );
};

const InvoiceDashboard = () => {
  const dispatch = useDispatch();
  const invoiceList = useSelector(state => state.invoiceList.inprogressInv);
  const authInfo = useSelector(state => state.authInfo);
  const [cancelReason, setCancelReason] = useState('');
  const [cancelReasonError, setCancelReasonError] = useState('');
  // InvoiceDashboard.jsx
  const overflowStyle = {whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',maxWidth: '150px'};
  const locationCellStyle = {...overflowStyle, maxWidth: '300px'};
  // Helper function to format elapsed time
  const formatElapsedTime = (startTime) => {
  const elapsedTime = new Date() - new Date(startTime);
  const totalSeconds = Math.floor(elapsedTime / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
// ElapsedTimeCell component
const ElapsedTimeCell = ({ startTime }) => {
  // Convert MM:SS string into total seconds
  const [minutes, seconds] = startTime.split(':').map(Number);
  const totalStartSeconds = minutes * 60 + seconds;

  // State to store the current elapsed time in seconds
  const [elapsedSeconds, setElapsedSeconds] = useState(totalStartSeconds);

  useEffect(() => {
    // Interval to update the elapsed time every second
    const intervalId = setInterval(() => {
      setElapsedSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Format the elapsed time back into MM:SS
  const formattedElapsedTime = () => {
    const elapsedMinutes = Math.floor(elapsedSeconds / 60).toString().padStart(2, '0');
    const elapsedSec = (elapsedSeconds % 60).toString().padStart(2, '0');
    return `${elapsedMinutes}:${elapsedSec}`;
  };

  return <span>{formattedElapsedTime()}</span>;
};
  useEffect(() => {
    if (authInfo && authInfo.userId) {
      dispatch(invoiceDateActions.getInvoicesData(authInfo.userId));
    }
  }, [dispatch, authInfo]);

  const [openModal, setOpenModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const handleCancelClick = (invoiceNumber) => {
    setSelectedInvoice(invoiceNumber);
    setOpenModal(true);
  };

  const handleConfirmCancel = () => {
    if (!cancelReason.trim()) {
      setCancelReasonError('Cancellation reason is required.');
      return; // Stop the execution if there is no cancellation reason
    }
    setCancelReasonError(''); // Clear any existing error

    dispatch(invoiceDateActions.cancelInvoice(selectedInvoice, cancelReason))
      .then(() => {
        // Success case
        alert("Invoice cancelled successfully");
        dispatch(invoiceDateActions.getInvoicesData(authInfo.userId)); // Refresh invoice data after successful cancellation
      })
      .catch((error) => {
        // Failure case
        alert("Failed to cancel invoice");
        console.error("Error cancelling invoice:", error);
      })
      .finally(() => {
        setOpenModal(false);
        setCancelReason(''); // Reset cancellation reason
      });
  };


  const handleModalClose = () => {
    setOpenModal(false);
  };

  const getColorCode = (priority) => {
    const priorityColorMapping = {
      1: "#CB0000", // red
      2: "#800080", // purple
      3: "#F36E07", // orange
      4: "#FFFF00", // yellow
      5: "#008000", // green
      6: "#3084FA", // blue
      7: "#3F2727", // brown
      8: "#D3D3D3", // light grey
      9: "#000000", // black
    };

    // Default color if priority is not found
    return priorityColorMapping[priority] || "#757575"; // grey
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div style={{ textAlign: 'center' }}>
            <div>Invoice #</div>
            <div>Status</div>
          </div>
        ),
        accessor: 'invoiceId',
        Filter: DefaultColumnFilter,
        width: 120, // Adjust width as needed
        Cell: ({ row }) => {
          const color = getColorCode(row.original.priority);
          return (
            <div style={{ textAlign: 'center' }}>
              <div style={{ backgroundColor: color, color: 'white', padding: '6px', display: 'block', borderRadius: '4px', minWidth: '100px', margin: 'auto' }}>
                {row.values.invoiceId}
              </div>
              <div style={{ color: color, padding: '4px', display: 'block', borderRadius: '4px', }}>
                {row.original.callStatus}
              </div>
            </div>
          );

        }
      },
      {
        Header: () => (
          <div style={{ textAlign: 'center' }}> <div>Requested</div> <div>By</div> </div>
        ),
        accessor: 'name',
        width: 120, // Adjust width as needed
        Filter: DefaultColumnFilter,
      },
      {
        Header: () => (<div style={{ textAlign: 'center' }}> <div>Reason</div><div>Rate Plan</div></div>),
        accessor: 'reason',
        Filter: DefaultColumnFilter,
        width: 120, // Adjust width as needed
        Cell: ({ row }) => (
          <div style={{ textAlign: 'center' }}>
            <div>{row.original.reason ? row.original.reason : ''}</div>
            <div>{row.original.accountPlanRateName ? row.original.accountPlanRateName : ''}</div>
          </div>
        )
      },
      {
        Header: () => (
          <div style={{ textAlign: 'center' }}>
            <div>Locations</div>
            <div>&nbsp;</div>
          </div>
        ),
        accessor: 'fromLocation',
        Filter: DefaultColumnFilter,
        width: 240, // Adjust width as needed
        Cell: ({ row }) => (
          <div>
            <div style={locationCellStyle}>
              <span style={{ fontWeight: 'bold', color: 'blue' }}>FROM:</span> {row.original.pickUpAddress}
            </div>
            <div style={locationCellStyle}>
              <span style={{ fontWeight: 'bold', color: 'blue' }}>TO:</span> {row.original.dropOffAddress}
            </div>
          </div>
        )
      },
      {
        Header: () => (
          <div style={{ textAlign: 'center' }}>
            <div>Driver</div>
            <div>Truck</div>
          </div>
        ),
        width: 120, // Adjust width as needed
        accessor: 'driver',
        Filter: DefaultColumnFilter,
        Cell: ({ row }) => (
          <div style={{ textAlign: 'center' }}>
            <div><span style={{ fontWeight: 'bold', color: 'blue' }}>Driver:</span> {row.original.driver || 'N/A'}</div>
            <div><span style={{ fontWeight: 'bold', color: 'blue' }}>Truck:</span> {row.original.truck || 'N/A'}</div>
          </div>
        )
      },

      {
        Header: () => (
          <div style={{ textAlign: 'center' }}>
            <div>Elapsed</div>
            <div>&nbsp;</div>
          </div>
        ),
        accessor: 'invoiceCreatedDate',
        Cell: ({ value }) => <ElapsedTimeCell startTime={value} />,
        // No need for Filter in this column
        disableFilters: true,
      },
      {
        Header: 'Actions',
        id: 'actions',
        accessor: 'actions',
        width: 120, // Adjust width as needed
        Cell: ({ row }) => (
          <div onClick={(e) => e.stopPropagation()}>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleCancelClick(row.original.invoiceId)}> {/* Pass the invoiceId to handleCancelClick */}
              Cancel
            </Button>
          </div>
        ),
        disableFilters: true, // Disables filtering for this column
      }
    ],
    []
  );

  const tableInstance = useTable(
    { columns, data: invoiceList || [] },
    useFilters, // use filters
    usePagination // add pagination functionality here
  );

  // In your InvoiceDashboard component...
  const theme = useTheme();

  const headerCellStyle = {
    padding: theme.spacing(1),
    fontWeight: 'bold',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 0,
    border: 'none',
    borderBottom: 'none',
    // Increase font size and ensure it has higher specificity
    //fontSize: '1.2rem !important',
  };

  const tableBodyCellStyle = { padding: theme.spacing(1), fontWeight: 'bold', textAlign: 'center', maxWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal' };
  // Function to merge specific column styles with general body cell styles
  const getCellStyles = (column) => {
    if (column.width) {
      return {
        ...tableBodyCellStyle,
        maxWidth: column.width, // Use specific column width
        width: column.width, // Ensure width is applied
      };
    }
    return tableBodyCellStyle; // Use general style if no specific width is defined
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we use page for pagination
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  return (
    <>
      <Typography variant="h3" sx={{ marginBottom: 1, padding: 1, textAlign: 'center', backgroundColor: 'rgb(193, 193, 193)' }}> Dashboard</Typography>

      <TableContainer component={Paper}
        sx={{
          '& .MuiTableCell-root, & .MuiTableCell-root *': { // Apply font size to all TableCell elements and their children
            fontSize: '10px',
          },
          '& .MuiTableCell-root': {
            padding: theme.spacing(1),
          }
        }}>
        <Table {...getTableProps()} sx={{ borderCollapse: 'collapse' }}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell
                    {...column.getHeaderProps()}
                    sx={{
                      ...headerCellStyle,
                      width: column.width, // Apply column width
                    }}
                  >
                    {column.render('Header')}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps({
                    onClick: () => window.open(`/invoice/${row.original.invoiceId}`, '_blank'),
                    style: { cursor: 'pointer' }
                  })}
                  sx={{
                    backgroundColor: index % 2 === 0 ? '' : '#f7f7f7', // Alternating row colors
                    '& .MuiTableCell-root': {
                      border: 0,
                    }
                  }}
                >
                  {row.cells.map(cell => (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={getCellStyles(cell.column)} // Apply merged styles
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>


      </TableContainer>
      {/* Enhanced Pagination */}
      <Typography variant="h3" sx={{ background: 'lightGray', marginBottom: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: 1 }}>
        <IconButton
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          sx={{ fontSize: 'large' }} // Adjust the size here
        >
          <FirstPageIcon sx={{ fontSize: 30, fontWeight: 'bold' }} />
        </IconButton>
        <IconButton
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          sx={{ fontSize: 'large' }}
        >
          <ChevronLeftIcon sx={{ fontSize: 30, fontWeight: 'bold' }} />
        </IconButton>
        <IconButton
          onClick={() => nextPage()}
          disabled={!canNextPage}
          sx={{ fontSize: 'large' }}
        >
          <ChevronRightIcon sx={{ fontSize: 30, fontWeight: 'bold' }} />
        </IconButton>
        <IconButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          sx={{ fontSize: 'large' }}
        >
          <LastPageIcon sx={{ fontSize: 30, fontWeight: 'bold' }} />
        </IconButton>
        <Typography sx={{ margin: theme.spacing(1) }}>
          Page {pageIndex + 1} of {pageOptions.length}
        </Typography>
        <Select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
          sx={{ marginLeft: theme.spacing(1) }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <MenuItem key={pageSize} value={pageSize}>
              {pageSize}
            </MenuItem>
          ))}
        </Select>
      </Typography>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 'auto', // Let the content dictate the size
              minWidth: 300, // Minimum width
              maxWidth: '50%', // Medium size on larger screens
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: theme.spacing(4),
              borderRadius: theme.shape.borderRadius,
              outline: 'none',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: theme.spacing(2),
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2" color="primary" gutterBottom>
              Confirm Cancellation
            </Typography>
            <Typography id="modal-modal-description">
              Are you sure you want to cancel invoice {selectedInvoice}?
            </Typography>
            <TextField
              label="Cancellation Reason"
              fullWidth
              variant="filled"
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
              error={!!cancelReasonError} // Show error state if there's a cancellation error
              helperText={cancelReasonError} // Display the error message
              sx={{ mt: 2 }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '100%', // Take up all available width
                mt: 3, // Margin top for spacing
              }}
            >
              <Button variant="contained" color="error" onClick={handleConfirmCancel}>
                Yes, Cancel
              </Button>
              <Button variant="outlined" onClick={handleModalClose}>
                No, Go Back
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
// Connect Redux state and actions to the Map component
const mapStateToProps = (state) => ({
  invoiceList: (state.invoiceList && state.invoiceList.inprogressInv) || [],
  authInfo: state.authInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoicesData: (user) => {
    dispatch(invoiceDateActions.getInvoicesData(user));
  },
  cancelInvoice: (invoiceId, reason) => { // Add cancelInvoice to mapDispatchToProps
    dispatch(invoiceDateActions.cancelInvoice(invoiceId, reason));
  }
});

export default withRouter(InvoiceDashboard);
