import React, { Component } from 'react';
import { connect } from 'react-redux';
import InvoiceThumbView from '../contentView/invoiceThumbView';
import InvoiceTableView from '../contentView/invoiceTableView';
import moment from 'moment';
import {utilMethods} from '../../util/util';
class InvoiceContent extends Component {
   constructor(props){
      super(props);      
      this.getEndDate=this.getEndDate.bind(this);
      this.bindInvoicesByDate=this.bindInvoicesByDate.bind(this);
   }
   getEndDate=(endDate)=>{
      let now=moment();
      if(endDate<=now){
        if(endDate.year()===now.year() && endDate.month()===now.month()){
          return now;
        }else{
          return endDate;
        }
      }
      else{
        return now;
      }
    }
   bindInvoicesByDate=()=>{
      if(this.props.invSearchRes.isSearch){
         return this.props.invSearchRes.data;
      }else{
         let startDate=moment(this.props.invoiceSelectedDate.selectedYear+'/'+this.props.invoiceSelectedDate.selectedMonth+'/01').startOf('month');
      let endDate=this.getEndDate(moment(this.props.invoiceSelectedDate.selectedYear+'/'+this.props.invoiceSelectedDate.selectedMonth+'/01').endOf('month'));
      let _monthDates=utilMethods.enumerateDaysBetweenDates(startDate,endDate);
      let listData=[]
      _monthDates.map((cDate,key)=>{        
          this.props.invoiceList.map(item=>{
           if(moment(item.createdDate).format('YYYY/MM/DD')===moment(cDate).format('YYYY/MM/DD')){
            listData.push(item);
           } 
         });
      });
      return listData;
      }
      
   }
    render() {      
      return (      
      <>
      <h3>Invoices for the month of {moment().month(this.props.invoiceSelectedDate.selectedMonth-1).format("MMMM")+" "+this.props.invoiceSelectedDate.selectedYear} </h3>
         {
            this.props.invoiceView.isTableView? 
               <InvoiceTableView 
               invoiceList={this.bindInvoicesByDate()} 
               invoiceSelectedDate={this.props.invoiceSelectedDate} /> : 
               <InvoiceThumbView 
               invoiceList={this.bindInvoicesByDate()} 
               invoiceSelectedDate={this.props.invoiceSelectedDate} />}
      </>)}
}
const mapStateToProps = (state) => {
  return {
     invoiceSelectedDate: state.invoiceSelectedDate,
     invoiceView: state.invoiceView,
     invoiceList:state.invoiceList.formattedList,
     invSearchRes:state.invSearchRes,
     spinner:state.spinner
  }
};
export default connect(mapStateToProps)(InvoiceContent);