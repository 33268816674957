import React, { Component } from 'react';
import { Switch, Redirect ,Route} from 'react-router-dom';

import Header from '../../components/layout/Header';
import LeftMenu from '../../components/layout/LeftMenu';
import InvoiceAttachments from '../../containers/Invoices/InvoiceAttachments';
import InvoiceSearchPage from '../../containers/Invoices/InvoiceSearchPage';
import VendorInvoiceView from '../../containers/Invoices/VendorInvoiceView';
import InvoiceDashboard from '../DashBoard/InvoiceDashboard';
import VendorReports from '../reports/VendorReports';
import Map from '../Map/Map';
import InvoiceContent from '../../components/layout/InvoiceContent';
import ProtectedRoute from '../../util/ProtectedRoute';
import { getPermissionByName } from '../../util/customMethods'; // Ensure correct path

class InvoiceHome extends Component {
  render() {
    // Determine if the user has dashboard permission
    const hasDashboardPermission = getPermissionByName("dashboard");

    return (
      <React.Fragment>
        <Header />
        <LeftMenu />
        <section className="main-content tile">
          <Switch>
          <ProtectedRoute exact path="/dashboard" component={InvoiceDashboard} permissionName="dashboard" />
          <ProtectedRoute exact path="/map" component={Map} permissionName="map" />
          <ProtectedRoute exact path="/search" component={InvoiceSearchPage} permissionName="search" />
            <ProtectedRoute exact path="/home/attachments" component={InvoiceAttachments} permissionName="towSheets" />
            <ProtectedRoute exact path="/home/" component={InvoiceContent} permissionName="towSheets" />
            <ProtectedRoute exact path="/ars-usage-report" component={VendorReports} permissionName="reports" />
            
            <Route exact path="/invoice/:invoiceId" component={VendorInvoiceView}  />
            
            {hasDashboardPermission && <Redirect to="/dashboard" />}
            {!hasDashboardPermission && <Redirect to="/home" />} {/* Adjust as necessary */}
          </Switch>
        </section>
      </React.Fragment>
    );
  }
}

export default InvoiceHome;
