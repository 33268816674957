import React, { Component,Fragment } from 'react';
import { withRouter,Link} from 'react-router-dom';
import { connect } from 'react-redux';
import * as authenticateActions from '../../actions/authenticateActions';
import {notify} from '../../util/notifications';
import { FormValidation } from "calidation";

class ForgetPassword extends Component { 
    constructor(){
        super();
        this.state={
            userName:''
        }
        this.onChangeValues=this.onChangeValues.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }
    onChangeValues=(event)=>{
        let _state=this.state;
        _state[event.currentTarget.name]=event.currentTarget.value;
        this.setState(_state);
    }
    handleSubmit=(fields, errors, isValid)=> {
        if (fields.isValid) {
            const { dispatch,history } = this.props;
            dispatch( authenticateActions.forgetPassword(fields.fields.userName,(success)=>{
                notify.createNotification('success','A Link has been sent to your mail. Please Click on the link and Reset your Password',this.props.refNotify);
                history.push('/login');
            },(error)=>{
                notify.createNotification('error','Something went wrong. Please contact administrator',this.props.refNotify);
            }));

         }else{

         }
    }
    render(){
        const config = {
            userName: {
              isRequired: "Email is required!"
            }
          };
        return(
            <div className="col-lg-3 col-md-6 col-sm-8 col-xs-12 align-middle">
        <div className="panel panel-default">
            <p className="text-center mb-lg">
            <br />
            <a href="#">
                <img src={require('../../assets/img/logo-2-white.png')} style={{height:'70px'}} alt="ACT" className="block-center img-rounded" />
            </a>
            </p>
            <p className="text-center mb-lg">
            <strong>Forgot your password?</strong>
            </p>
            <div className="panel-body">
            <FormValidation name="frmForgetPwd" className="mb-lg" config={config}
                     onSubmit={  this.handleSubmit
                    } >
                    {({ fields, errors, submitted }) => (
                    <Fragment> 
                    <p class="text-center">Fill with your mail to receive instructions on how to reset your password.</p>                        
                           <div className={(submitted && errors.userName)?"form-group has-feedback has-error has-danger":"form-group has-feedback"}>
                              <label for="txtEmail">Email address</label>
                              <input id="txtEmail" name="userName" type="Email" placeholder="Enter email" className="form-control" value={fields.userName}
                              onChange={this.onChangeValues} />
                              <span className="fa fa-lock form-control-feedback text-muted"></span>
                              {(submitted && errors.userName) ? 
                                <div className="invalid-feedback with-errors" >
                                <span className="text-danger">{errors.userName}</span>
                                </div>
                                : null}
                           </div>
                           
                           <div className="clearfix">                              
                           </div>
                           <button type="submit" className="btn btn-block btn-primary">Reset</button>
                           <Link to="/login" className="btn btn-block btn-danger">Back</Link>
                           </Fragment>
                    )}
         </FormValidation>

            </div>
            </div>
        
        </div>)
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
      authInfo: state.authInfo,
      refNotify:state.refNotify
    }
  };
 
export default withRouter(connect(mapStateToProps)(ForgetPassword))