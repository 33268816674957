import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getPermissionByName } from '../util/customMethods';
import AccessDenied from '../util/AccessDenied'; 

const ProtectedRoute = ({ component: Component, permissionName, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        getPermissionByName(permissionName) ? (
          <Component {...props} />
        ) : (
          <AccessDenied />
        )
      }
    />
  );
  
  export default ProtectedRoute;
