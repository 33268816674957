import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
    Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Button,
    Paper, Box, Typography, TablePagination
} from '@mui/material';
import Papa from 'papaparse';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import { vendorReport } from '../../actions/invoiceDateActions'; // Adjust the path as needed
import { useTheme } from '@mui/material/styles'; // Import useTheme
import { styled } from '@mui/system'; // For custom styling

const VendorReports = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [reportData, setReportData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openstart, setOpenStart] = useState(false);
    const [openEnd, setOpenEnd] = useState(false);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false); // New state for loading indicator
    const [error, setError] = useState(null); // New state for handling errors
    const theme = useTheme(); // Use the theme

    const StyledTableContainer = styled(TableContainer)({
        marginTop: theme.spacing(2),
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: theme.shape.borderRadius,
    });

    const StyledTableCellHead = styled(TableCell)({
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: 'bold',
        fontSize: '10px', // Set font size for header
    });

    const StyledTableCellBody = styled(TableCell)({
        fontSize: '9px', // Set font size for body
    });
    const StyledTablePagination = styled(TablePagination) ({
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-select, .MuiTablePagination-actions': {
            color: 'inherit', // Inherits the white color
        }
    });

    const formatDateToYYYYMMDD = (date) => {
        if (!date) return '';

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-indexed
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const handleGenerateReport = () => {
        const formattedStartDate = formatDateToYYYYMMDD(startDate);
        const formattedEndDate = formatDateToYYYYMMDD(endDate);
        setIsLoading(true);
        setError(null);
        dispatch(vendorReport(formattedStartDate, formattedEndDate))
            .then(invoiceData => {
                setReportData(invoiceData);
                setIsLoading(false);
            })
            .catch(err => {
                console.error("Error while fetching report data:", err);
                setError("Failed to fetch report data");
                setIsLoading(false);
            });
    };

    const handleExport = () => {
        const formattedStartDate = formatDateToYYYYMMDD(startDate);
        const formattedEndDate = formatDateToYYYYMMDD(endDate);
        setIsLoading(true);
        setError(null);
        dispatch(vendorReport(formattedStartDate, formattedEndDate))
            .then(invoiceData => {
                if (invoiceData && invoiceData.length) {
                    setIsLoading(false);
                    const csv = Papa.unparse(invoiceData);
                    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    const link = document.createElement("a");
                    const url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", "VendorReport.csv");
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                }
            })
            .catch(err => {
                console.error("Error while exporting report data:", err);
                setError("Failed to fetch report data");
                setIsLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                ARS Usage Report
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ display: 'flex', gap: 2, mb: 3, }}>
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        open={openstart}
                        onClose={() => setOpenStart(false)}
                        onChange={(newValue) => {
                            setStartDate(newValue);
                        }}
                        slotProps={{ textField: { variant: 'filled', size: 'small', onClick: () => setOpenStart(true) } }}
                    />
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        open={openEnd}
                        onClose={() => setOpenEnd(false)}
                        onChange={(newValue) => {
                            setEndDate(newValue);
                        }}
                        slotProps={{ textField: { variant: 'filled', size: 'small', onClick: () => setOpenEnd(true) } }}
                    />
                    <Box sx={{ minWidth: 'fit-content' }}>
                        <Button variant="contained" color="primary" onClick={handleGenerateReport} startIcon={<SearchIcon />}>
                            Go
                        </Button>

                    </Box>
                    <Box sx={{ minWidth: 'fit-content' }}>
                        <Button variant="contained" color="secondary" startIcon={<FileDownloadIcon />} onClick={handleExport}>
                            Export
                        </Button>
                    </Box>
                </Box>
            </LocalizationProvider>
            {isLoading && <Typography>Loading...</Typography>}
            {error && <Typography color="error">{error}</Typography>}
            {reportData.length > 0 && (
                <>
                    <StyledTableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCellHead>Invoice Number</StyledTableCellHead>
                                    <StyledTableCellHead>Service Call Date</StyledTableCellHead>
                                    <StyledTableCellHead>Pickup Address</StyledTableCellHead>
                                    <StyledTableCellHead>Input Method</StyledTableCellHead>
                                    <StyledTableCellHead>Caller Name</StyledTableCellHead>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                                    <TableRow key={index}>
                                        <StyledTableCellBody>{data.invoiceId}</StyledTableCellBody>
                                        <StyledTableCellBody>{data.serviceCallDate}</StyledTableCellBody>
                                        <StyledTableCellBody>{data.pickUpAddress}</StyledTableCellBody>
                                        <StyledTableCellBody>{data.inputMethod}</StyledTableCellBody>
                                        <StyledTableCellBody>{data.callerName}</StyledTableCellBody>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </StyledTableContainer>
                    <StyledTablePagination
                        component="div"
                        count={reportData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Box>
    );
};

export default VendorReports;
