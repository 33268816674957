import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import { Spinner } from 'react-redux-spinner';

import LoginPage from './containers/Authentication/loginPage';
import ResetPwd from './containers/Authentication/resetPwd';
import ForgetPwdPage from './containers/Authentication/forgetPwdPage';
import InvoiceHome from './containers/Invoices/InvoiceHome';  

import * as authenticateActions from './actions/authenticateActions';
import * as notificationActions from './actions/notificationActions';
import { PrivateRoute } from './components/privateRoute';
import ProtectedRoute from './util/ProtectedRoute';

import './assets/css/nprogress.css';
import Background from './assets/img/bg_body.jpg';
import vehiclesImage from './assets/img/trucks.png';

const styleCss = {
  trucks: {
    width: '100%',
    maxWidth: '600px',
    position: 'absolute',
    bottom: '0',
    right: '0',
    lineHeight: '0'
  },
  imgScale: {
    width: '100%'
  }
};

class App extends Component {
  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
    this.props.setNotifyRef(this.refs.notificationSystem);
  }
  
  render() {
    return (
      <section className="wrapper" style={{
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover"
      }}>
        <NotificationSystem ref="notificationSystem" />
        <Spinner />
        <div style={styleCss.trucks}>
          <img src={vehiclesImage} alt="" style={styleCss.imgScale} />
        </div>
        <Router>
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/resetPwd" component={ResetPwd} />
            <Route exact path="/forgetPwd" component={ForgetPwdPage} />
            <Route path="/" component={InvoiceHome} />
          </Switch>
        </Router>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  authInfo: state.authInfo
});

const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (_userName, _password) => {
    dispatch(authenticateActions.authenticateUser(_userName, _password));
  },
  setNotifyRef: (_notifyRef) => {
    dispatch(notificationActions.setNotificationRef(_notifyRef));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
