import * as actionTypes from '../actions/actionTypes';
import moment from 'moment'
const defaultInvoiceView={ isTableView:false}  
        

export const invoiceViewReducer = (state = defaultInvoiceView, action) => {
    switch (action.type) {
      case actionTypes.INVOICE_VIEW_TRIGGER:
        return action.invoiceView;
      default:
        return state;
    }
  };