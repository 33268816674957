import * as actionTypes from './actionTypes';
import { pendingTask, begin, end, endAll } from 'react-redux-spinner';
export const showSpinner = () => {
    return {
        type: actionTypes.ANY_OF_YOUR_ACTION_TYPES,
        [pendingTask]: begin
    }
}
export const hideSpinner = () => {
    return {
        type: actionTypes.ANY_OF_YOUR_ACTION_TYPES_FINISH,
        [pendingTask]: endAll
    }
}

