import {store} from '../store/configureStore';
import * as actionTypes from '../actions/actionTypes';
import {serviceHTTP} from '../util/service';
import moment from 'moment';
import { faL } from '@fortawesome/free-solid-svg-icons';
export const getAccessToken=()=>{
    if( Object.keys((store.getState()).authInfo).length>0){
      return (store.getState()).authInfo.access_token;
    }else{
      return undefined;  
    }
}

export const getPermissionByName = (name) => {
  const state = store.getState();
  if (state.authInfo && Object.keys(state.authInfo).length > 0) {
    const authInfo = state.authInfo;
    return authInfo.permissions && authInfo.permissions[name] !== undefined ? authInfo.permissions[name] : false;
  } else {
    return false;
  }
};

export const refreshToken=()=>{
  return (dispatch)=>{
    if(Object.keys((store.getState()).authInfo).length>0){
      return serviceHTTP.getAccessTokenWithRefreshToken((store.getState()).authInfo.refresh_token)
      .then(response => {
        if([401,400].includes(response.status)){
          dispatch(clearSessionData());
          window.location.href='/login';
        }else{
          let _respObj=(store.getState()).authInfo;
          _respObj.access_token=response.access_token;
          _respObj.refresh_token=response.refresh_token;
          _respObj.token_creationTime=response.expires_in;
          dispatch(
            {
                type: actionTypes.AUTHENTICATE_USER,
                authInfo:_respObj
            });
        }
      })
      .catch(error => {
          dispatch(clearSessionData());
          window.location.href='/login';
          throw(error);
        }); 

    }else{
      dispatch(clearSessionData());
      window.location.href='/login';
    }
  }
}
export const clearSessionData=()=>{
  return (dispatch)=>{
    dispatch(
      {
          type: actionTypes.AUTHENTICATE_USER,
          authInfo:{}
      })
      dispatch({
        type: actionTypes.GET_INVOICE_LIST,
        invoiceList:[]
    });
    dispatch({
      type: actionTypes.SET_INVOICE_SELECTED_DATE,
      invoiceSelectedDate:{
             selectedYear:parseInt(moment().format('YYYY')) ,
             selectedMonth:parseInt(moment().format('MM')) ,
             selectedDate:0
         }
     });
  }  
}