import * as actionTypes from './actionTypes';
import moment from 'moment';
import { serviceHTTP } from '../util/service';
import * as spinnerActions from '../actions/spinnerActions';
import { getAccessToken, refreshToken } from '../util/customMethods';
import { store } from '../store/configureStore';
const createObject = (invoiceId, reason) => ({
    invoiceId: invoiceId,
    serviceCallId: null,
    callStatus: "Cancel",
    reason: reason,
    statusValue: "Edit"
});

export const setInvoiceSelectedDate = (year, month, date) => {
    return (dispatch) => {
        dispatch(clearSearchInvoice());
        dispatch(
            {
                type: actionTypes.SET_INVOICE_SELECTED_DATE,
                invoiceSelectedDate: {
                    selectedYear: year,
                    selectedMonth: month === 0 ? '' : month,
                    selectedDate: date === 0 ? '' : date
                }
            });


    }
};

export const getInvoiceMonthYears = (userId) => {
    const _url = 'getDataofyearAndMonth?userId=' + userId
    return (dispatch) => {
        dispatch(spinnerActions.showSpinner());
        return serviceHTTP.get(_url, undefined, getAccessToken())
            .then(response => {
                if (response.error == undefined) {
                    dispatch({
                        type: actionTypes.GET_INVOICE_MONTH_YEARS,
                        invoiceMonthYears: formatInvoiceMonthYears(response, dispatch, userId)
                    });
                } else {
                    if (response.status === 401) {
                        dispatch(refreshToken());
                    } else {
                        dispatch({
                            type: actionTypes.GET_INVOICE_MONTH_YEARS,
                            invoiceMonthYears: {}
                        });
                    }
                }
                dispatch(spinnerActions.hideSpinner());
            })
            .catch(error => {
                dispatch(spinnerActions.hideSpinner());
                throw (error);
            });

    }
}

export const getInvoicesData = (userId) => {
    const _url = 'invoices?userId=' + userId
    return (dispatch) => {
        dispatch(spinnerActions.showSpinner());
        return serviceHTTP.get(_url, undefined, getAccessToken())
            .then(response => {
                dispatch(spinnerActions.hideSpinner());
                if (response.status === 200) {
                    dispatch({
                        type: actionTypes.INVOICESDATA,
                        invoices: response || []
                    });
                } else {
                    if (response.status === 401) {
                        dispatch(refreshToken());
                    }
                }
            })
            .catch(error => {
                dispatch(spinnerActions.hideSpinner());
                console.error("Error fetching invoices data:", error);
            });
    }
}

export const cancelInvoice = (invoiceId, reason) => {
    const dynamicObject = createObject(invoiceId, reason);
    const _url = '/dashboard/cancel/serviceCall';
    return (dispatch) => {
        dispatch(spinnerActions.showSpinner());
        return new Promise((resolve, reject) => {
            serviceHTTP.postToDispatch(_url, dynamicObject, undefined, getAccessToken()) 
                .then(response => {
                    dispatch(spinnerActions.hideSpinner());
                    if (response.status === 200) {
                        resolve(response); 
                    } else {
                        if (response.status === 401) {
                            dispatch(refreshToken());
                        }
                        reject(new Error("Failed to cancel invoice")); 
                    }
                })
                .catch(error => {
                    dispatch(spinnerActions.hideSpinner());
                    console.error("Error cancelling invoice:", error);
                    reject(error); 
                });
        });
    };
};

export const searchInvoiceMetaData = (searchParams) => {
    const query = new URLSearchParams(searchParams).toString();
    const _url = `search?${query}`;
    return (dispatch) => {
        dispatch(spinnerActions.showSpinner());
        return serviceHTTP.get(_url, undefined, getAccessToken())
            .then(response => {
                dispatch(spinnerActions.hideSpinner());

                if (response.status === 200) {
                    dispatch({
                        type: actionTypes.INVOICE_SEARCH_RESULT,
                        invoiceSearchData: response || []
                    });
                    return response.data;
                } else {
                    if (response.status === 401) {
                        dispatch(refreshToken());
                        return []; // Return an empty array or handle appropriately
                    }
                }
            })
            .catch(error => {
                dispatch(spinnerActions.hideSpinner());
                console.error("Error fetching invoices data:", error);
            });
    }
};

export const vendorReport = (start, enddate) => {
    const searchParams = { startDate: start, endDate: enddate };
    const query = new URLSearchParams(searchParams).toString();
    const _url = `vendorReport?${query}`; // Adjusted URL
    return (dispatch) => {
        dispatch(spinnerActions.showSpinner());
        return serviceHTTP.get(_url, undefined, getAccessToken())
            .then(response => {
                dispatch(spinnerActions.hideSpinner());
                if (response.status === 200) {
                    const invoiceData = response || [];
                    return invoiceData;
                } else {
                    if (response.status === 401) {
                        dispatch(refreshToken());
                        return []; // Return an empty array or handle appropriately
                    }
                    return []; // Default return for other status codes
                }
            })
            .catch(error => {
                dispatch(spinnerActions.hideSpinner());
                return []; // Return an empty array or appropriate error response
            });
    };
};

export const fetchInvoice = (invId) => {
    const _url = `invoice/${invId}`;
    return (dispatch) => {
        dispatch(spinnerActions.showSpinner());
        return serviceHTTP.get(_url, undefined, getAccessToken())
            .then(response => {
                dispatch(spinnerActions.hideSpinner());
                if (response.status === 200) {
                    dispatch({
                        type: actionTypes.INVOICE_DETAILS,
                        invoiceDetails: response || {}
                    });
                    return response.data;
                } else {
                    if (response.status === 401) {
                        dispatch(refreshToken());
                        return []; 
                    }
                }
            })
            .catch(error => {
                dispatch(spinnerActions.hideSpinner());
                console.error("Error fetching invoices data:", error);
            });
    }
};

export const fetchInvoiceNotes = (invId) => {
    const _url = `byInvoice/${invId}`;
    return (dispatch) => {
        dispatch(spinnerActions.showSpinner());
        return serviceHTTP.get(_url, undefined, getAccessToken())
            .then(response => {
                dispatch(spinnerActions.hideSpinner());
                if (response.status === 200) {
                    dispatch({
                        type: actionTypes.INVOICE_NOTES,
                        invoicenotes: response || []
                    });
                    return response.data;
                } else {
                    if (response.status === 401) {
                        dispatch(refreshToken());
                        return []; 
                    }
                }
            })
            .catch(error => {
                dispatch(spinnerActions.hideSpinner());
                console.error("Error fetching invoices data:", error);
            });
    }
};

export const saveNotes = ( noteObj,id ) => {
    const _url = `addorupdate`;
    return (dispatch) => {
        return serviceHTTP.post(_url, noteObj, undefined, getAccessToken())
            .then(response => {
                if (response.status === 200) {
                    fetchInvoiceNotes(id);
                    return response.data;
                } else if (response.status === 401) {
                    dispatch(refreshToken()); 
                    return [];
                }
            })
            .catch(error => {
                console.error("Error in saving notes:", error);
            });
    };
};


export const resetInvoiceData = () => {
    return {
        type: actionTypes.RESET_INVOICE_DATA
    };
};

export const getInvoiceList = (userId, year = 0, month = 0) => {
    const _url = 'getAttachmentDataByInputs?userId=' + userId + '&year=' + year + '&month=' + month
    return (dispatch) => {
        dispatch(spinnerActions.showSpinner());
        return serviceHTTP.get(_url, undefined, getAccessToken())
            .then(response => {
                if (response.error == undefined) {
                    dispatch({
                        type: actionTypes.GET_INVOICE_LIST,
                        invoiceList: response
                    });
                } else {
                    if (response.status === 401) {
                        dispatch(refreshToken());
                    } else {
                        dispatch({
                            type: actionTypes.GET_INVOICE_LIST,
                            invoiceList: []
                        });
                    }
                }
                dispatch(spinnerActions.hideSpinner());
            })
            .catch(error => {
                dispatch(spinnerActions.hideSpinner());
                throw (error);
            });

    }
}

export const searchInvoice = (invNo) => {
    return (dispatch) => {
        let invList = (store.getState()).invoiceList;
        let _searchInvList = invList.filter(item => {
            return Object.keys(item.attachments).includes(invNo);
        });
        dispatch({
            type: actionTypes.SEARCH_INVOICE_NO,
            searchResult: { data: _searchInvList, isSearch: true }
        });
        if (_searchInvList.length > 0) {
            const _invDate = moment(_searchInvList[0].createdDate)
            dispatch({
                type: actionTypes.SET_INVOICE_SELECTED_DATE,
                invoiceSelectedDate: {
                    selectedYear: parseInt(_invDate.format('YYYY')),
                    selectedMonth: parseInt(_invDate.format('MM')),
                    selectedDate: parseInt(_invDate.format('DD'))
                }
            });
        }

    }
}

export const clearSearchInvoice = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CLEAR_SEARCH_INVOICE_NO,
            searchResult: { data: [], isSearch: false }
        })
    }

}

export const changeInvoiceView = (isTableView = false) => {
    return {
        type: actionTypes.INVOICE_VIEW_TRIGGER,
        invoiceView: { isTableView: isTableView }
    };
}

const formatInvoiceMonthYears = (list, dispatch, userId) => {
    let _formatedList = list.reduce(
        (result, item) => ({
            ...result,
            [item['year']]: [
                ...(result[item['year']] || []),
                {
                    month: item.monthName,
                    monthNo: item.month
                }
            ].sort((x, y) => { return y.monthNo - x.monthNo }),
        }),
        {},
    );
    if (Object.keys(_formatedList).length > 0) {
        let yyyy = Object.keys(_formatedList).sort((a, b) => { return b - a })[0];
        dispatch({
            type: actionTypes.SET_INVOICE_SELECTED_DATE,
            invoiceSelectedDate: {
                selectedYear: parseInt(yyyy),
                selectedMonth: parseInt(_formatedList[yyyy][0].monthNo),
                selectedDate: 0
            }
        });
        dispatch(getInvoiceList(userId, yyyy, _formatedList[yyyy][0].monthNo));
    }
    return _formatedList;
}


