import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import queryString from 'query-string'
import * as bootstrap from 'react-bootstrap/dist/react-bootstrap';
import {utilMethods} from '../../util/util';
import moment from 'moment';
import ImageView from '../contentView/imageView';
import download from 'downloadjs';
import '../contentView/invoiceView.css';
import testImagePath from '../../assets/img/180928-0018-28092018061803240phil21538140683230 - Copy.jpeg';
const imageFormats=['jpg','png','jpeg','gif'];

class InvoiceAttachmentsView extends Component {
    constructor (){
        super();
        this.state={
            showLightBox:false,
            imagePath:'',
            selectedId:'',
            selectedDate:'',
            attachments:[]
        }
        this.closeLightBox=this.closeLightBox.bind(this);
        this.bindInvoiceAttachments=this.bindInvoiceAttachments.bind(this);
    }
    componentWillMount=()=>{
        const value=queryString.parse(this.props.location.search);
        this.setState({selectedId:value.id,selectedDate:value.date},
           this.bindInvoiceAttachments(value.date,value.id))
    }
    bindInvoiceAttachments=(cDate,iNo)=>{
        let listData= this.props.invoiceList.filter(item=>{
           return moment(item.createdDate).format('YYYY/MM/dd')===moment(cDate).format('YYYY/MM/dd')
         });
         let invoiceAttachments=[]
         listData.map(item=>{
            Object.keys(item.attachments).map(invNo=>{
                if(invNo==iNo){
                    invoiceAttachments.push(...item.attachments[invNo])
                }
            })
            
         });
         this.setState({attachments:invoiceAttachments});
       }
    closeLightBox=()=>{
        this.setState({showLightBox:false})
    }
render(){
    return(
    <div className="row list-icon">
   { 
       this.state.attachments.map((att)=>{
        return (
        <div className="col-md-3 col-sm-4"> 
            <div className="panel widget">
                <div className="row row-table row-flush">
                    <div className="col-xs-8">{
                        imageFormats.includes(att.attachmentURL.split('.').pop())?                        
                        <img src={att.attachmentURL} alt="" className="img-responsive" />:
                        <div className="img-responsive" style={{fontSize:'82px',textAlign:'center'}}><i className="fa fa-file-pdf-o"></i></div>
                    }                       
                    </div>
                    <div className="col-xs-4 align-middle bg-info">
                        <div className="text-center"> {
                            imageFormats.includes(att.attachmentURL.split('.').pop())?
                            <button type="button" className="btn btn-link text-white" onClick={
                                ()=>{
                                   this.setState({
                                    showLightBox:true,
                                    imagePath:att.attachmentURL
                                   })
                                }
                            } ><i className="fa fa-search-plus fa-2x"></i></button>:null
                        }                                                               
                             
                            <button type="button" className="btn btn-link text-white" onClick={
                                ()=>{
                                   window.open(att.attachmentURL);
                                }
                            }> <i className="fa fa-download fa-2x"></i></button>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
        )
    })
   }
   <ImageView imagePath={this.state.imagePath} lightboxIsOpen={this.state.showLightBox} closeLightbox={this.closeLightBox}  /> 
    </div>)
    }
}
const mapStateToProps = (state) => {
    return {
       invoiceSelectedDate: state.invoiceSelectedDate,
       invoiceList:state.invoiceList.formattedList
    }
  };
  export default withRouter(connect(mapStateToProps)(InvoiceAttachmentsView));