import React, { useState, useMemo, useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { Typography } from '@mui/material';
import * as invoiceDateActions from '../../actions/invoiceDateActions';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import truckMarkerImage from '../../assets/img/freeTruck.png'; 
const greenMarkerIcon = "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
const redMarkerIcon = "http://maps.google.com/mapfiles/ms/icons/red-dot.png";

const Map = (props) => {
    const { getInvoicesData, authInfo, invoiceList } = props;
    const truckMarkerIcon = {
        url: truckMarkerImage
    };
    useEffect(() => {
        if (authInfo && authInfo.userId) {
            getInvoicesData(authInfo.userId);
        }
    }, [getInvoicesData, authInfo]);

    const mapStyles = { height: `80vh`, width: "100%" };

    const defaultCenter = { lat: 33.4255, lng: -111.9400 };
    const locations = useMemo(() => (invoiceList || []).map(invoice => {
        const fromCoords = invoice.fromLocation.split(',').map(Number);
        const toCoords = invoice.toLocation.split(',').map(Number);
        const truckCoords = invoice.truckPosition ? invoice.truckPosition.split(',').map(Number) : [0, 0]; 
    
        return {
            id: invoice.invoiceId,
            status: invoice.callStatus,
            from: {
                lat: fromCoords[0],
                lng: fromCoords[1],
                type: 'pickup',
                info: {
                    address: invoice.pickUpAddress,
                    status: invoice.callStatus,
                    driver: invoice.driver
                }
            },
            to: {
                lat: toCoords[0],
                lng: toCoords[1],
                type: 'dropoff',
                info: {
                    address: invoice.dropOffAddress,
                    status: invoice.callStatus,
                    driver: invoice.driver
                }
            },
            truck: { 
                lat: truckCoords[0],
                lng: truckCoords[1],
                type: 'truck',
                info: {
                    address: invoice.turckCurrentAddress||'',
                    status: invoice.callStatus,
                    driver: invoice.driver
                }
            }
        };
    }), [invoiceList]);
    
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [routeVisible, setRouteVisible] = useState(false);

    const handleMarkerClick = (location, correspondingLocation) => {
        if (selectedMarker && selectedMarker.id === location.id) {
            setSelectedMarker(null);
            setRouteVisible(false);
        } else {
            setSelectedMarker(location);

            const DirectionsServiceOptions = {
                origin: location,
                destination: correspondingLocation,
                travelMode: window.google.maps.TravelMode.DRIVING,
            };

            setDirectionsResponse(null); 
            setRouteVisible(true);

            new window.google.maps.DirectionsService().route(DirectionsServiceOptions, (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirectionsResponse(result);
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            });
        }
    };

    const handleMarkerClose = () => {
        setSelectedMarker(null);
        setRouteVisible(false);
    };

    const handleMapClick = () => {
        setSelectedMarker(null);
        setRouteVisible(false);
    };

    // const fitBoundsToMarkers = (map) => {
    //     const bounds = new window.google.maps.LatLngBounds();
    //     locations.forEach(location => {
    //         bounds.extend(new window.google.maps.LatLng(location.from.lat, location.from.lng));
    //         bounds.extend(new window.google.maps.LatLng(location.to.lat, location.to.lng));
    //         if (location.truck && location.truck.lat != null && location.truck.lng != null && (location.truck.lat !== 0 || location.truck.lng !== 0)) {
    //             bounds.extend(new window.google.maps.LatLng(location.truck.lat, location.truck.lng));
    //         }
    //     });
    //     map.fitBounds(bounds);
    // };
    const fitBoundsToMarkers = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        locations.forEach(location => {
            if (location.from.lat && location.from.lng) {
                bounds.extend(new window.google.maps.LatLng(location.from.lat, location.from.lng));
            }
            if (location.to.lat && location.to.lng) {
                bounds.extend(new window.google.maps.LatLng(location.to.lat, location.to.lng));
            }

            if (location.truck && location.truck.lat && location.truck.lng) {
                bounds.extend(new window.google.maps.LatLng(location.truck.lat, location.truck.lng));
            }
        });

        if (!bounds.isEmpty()) {
            map.fitBounds(bounds);
        } else {
            map.setCenter(defaultCenter);
        }
    };

    

    return (
        <>
            <Typography variant="h3" sx={{ marginBottom: 1, padding: 1, textAlign: 'center', backgroundColor: 'rgb(193, 193, 193)' }}>Map</Typography>
            <LoadScript googleMapsApiKey="AIzaSyCPhSP57_v3UIrDN-9EAB8uXoa6m4pHhr0">
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={10}
                    center={defaultCenter}
                    onClick={handleMapClick} 
                    onLoad={fitBoundsToMarkers}
                >
                    {locations.map(location => (
                        <>
                            <Marker
                                key={`from-${location.id}`}
                                position={location.from}
                                onClick={() => handleMarkerClick(location.from,location.status === "Enroute" ? location.truck : location.to)}
                                icon={redMarkerIcon}
                            />
                            <Marker
                                key={`to-${location.id}`}
                                position={location.to}
                                onClick={() => handleMarkerClick(location.to, location.from)}
                                icon={greenMarkerIcon}
                            />
                        {location.truck && location.truck.lat != null && location.truck.lng != null && (location.truck.lat !== 0 || location.truck.lng !== 0) && (
                            <Marker
                                key={`truck-${location.id}`}
                                position={{ lat: location.truck.lat, lng: location.truck.lng }}
                                icon={truckMarkerIcon}
                                onClick={() => handleMarkerClick(location.truck, null)}
                            />
                        )}
                            {selectedMarker && (
                                <InfoWindow
                                    position={selectedMarker}
                                    onCloseClick={handleMarkerClose}
                                >
                                    <div>
                                        {selectedMarker.type === 'pickup' && (
                                            <>
                                                <div><strong>Pickup:</strong> {selectedMarker.info.address}</div>
                                                <div><strong>Status:</strong> {selectedMarker.info.status}</div>
                                                <div><strong>Driver:</strong> {selectedMarker.info.driver}</div>
                                            </>
                                        )}
                                        {selectedMarker.type === 'dropoff' && (
                                            <>
                                                <div><strong>Drop-off:</strong> {selectedMarker.info.address}</div>
                                                <div><strong>Status:</strong> {selectedMarker.info.status}</div>
                                                <div><strong>Driver:</strong> {selectedMarker.info.driver}</div>
                                            </>
                                        )}
                                         
                                    </div>
                                </InfoWindow>
                            )}
                        </>
                    ))}
                    {routeVisible && directionsResponse && (
                        <DirectionsRenderer
                            options={{
                                directions: directionsResponse,
                                polylineOptions: {
                                    strokeColor: '#000000', 
                                    strokeWeight: 6, 
                                },
                            }}
                        />
                    )}
                </GoogleMap>
            </LoadScript>
        </>
    );
};
const mapStateToProps = (state) => ({
    invoiceList: (state.invoiceList && state.invoiceList.inprogressInv) || [],
    authInfo: state.authInfo,
});

const mapDispatchToProps = (dispatch) => ({
    getInvoicesData: (user) => {
        dispatch(invoiceDateActions.getInvoicesData(user));
    }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Map));
