import storage from 'redux-persist/es/storage';
// import storageSession  from 'redux-persist/lib/storage/session'; // Not needed anymore
import { persistCombineReducers } from 'redux-persist'
import { authenticateReducer } from './authenticateReducer'
import { invoiceDateReducer, invoiceSearchReducer, invoiceMonthYearsReducer } from './invoiceDateReducer'
import { invoiceListReducer } from './invoiceListReducer';
import { invoiceViewReducer } from './invoiceViewReducer';
import { setNotificationRefReducer } from './setNotificationRefReducer'
import { pendingTasksReducer } from 'react-redux-spinner';

export default persistCombineReducers({ key: 'root', storage: storage, blacklist: ['refNotify', 'invSearchRes'] }, {
  authInfo: authenticateReducer,
  invoiceList: invoiceListReducer,
  invoiceSelectedDate: invoiceDateReducer,
  invoiceView: invoiceViewReducer,
  pendingTasks: pendingTasksReducer,
  refNotify: setNotificationRefReducer,
  invSearchRes: invoiceSearchReducer,
  invoiceMonthYears: invoiceMonthYearsReducer
  // cart
});
