import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as bootstrap from 'react-bootstrap/dist/react-bootstrap';
import LogIn from '../../components/authComponents/login';
class LogInPage extends Component {
   
    render() {
      return (<>
        <div style={{height: "100%", padding: "50px 0"}} className="row row-table">
            <LogIn />
   </div>
          </>
      )
    }
}
export default LogInPage;