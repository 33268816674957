import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as bootstrap from 'react-bootstrap/dist/react-bootstrap';
import { changeInvoiceView, searchInvoice, clearSearchInvoice } from '../../actions/invoiceDateActions.js';
import { logoutUser } from '../../actions/authenticateActions';
import { notify } from '../../util/notifications';
import { format } from 'path';
import { findDOMNode } from 'react-dom';

const imageSize = {
    width: '48px', height: '48px'
}

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeDropdown: null
        };
        this.node = React.createRef();
        this.logout = this.logout.bind(this);
        this.searchHandleKeyPress = this.searchHandleKeyPress.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false);
    }

    handleOutsideClick = (event) => {
        const node = findDOMNode(this.node.current);
        if (node && node.contains(event.target)) {
            return;
        }
        this.closeDropdown();
    }

    logout = () => {
        const { dispatch, history } = this.props;
        dispatch(logoutUser((success) => {
            localStorage.clear();
            history.push('/login');
        }, (error) => {
            localStorage.clear();
            history.push('/login');
            // notify.createNotification('error', 'Something went wrong. Please contact administrator', this.props.refNotify);
        }));
    }

    searchHandleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const { dispatch, history } = this.props;
            dispatch(searchInvoice(event.target.value));
            history.push('/home');
        }
    }

    clearSearch = () => {
        const { dispatch } = this.props;
        dispatch(clearSearchInvoice());
    }

    toggleDropdown = (dropdown) => {
        this.setState({ activeDropdown: this.state.activeDropdown === dropdown ? null : dropdown });
    }

    closeDropdown = () => {
        this.setState({ activeDropdown: null });
    }

    render() {
        const { activeDropdown } = this.state;

        return (
            <bootstrap.Navbar collapseOnSelect fluid="true" bsStyle="navbar navbar-default navbar-top navbar-fixed-top">
                <bootstrap.Navbar.Header className="navbar-header">
                    <bootstrap.Navbar.Brand className="navbar-brand">
                        <a href="#" className="navbar-brand">
                            <div className="brand-logo">
                                <bootstrap.Image src={require('../../assets/img/logo-2-white.png')} height="50px" />
                            </div>
                            <div className="brand-logo-collapsed">
                                <bootstrap.Image src={require('../../assets/img/logo-2-white.png')} height="40px" />
                            </div>
                        </a>
                    </bootstrap.Navbar.Brand>
                </bootstrap.Navbar.Header>
                <bootstrap.Navbar.Collapse className="nav-wrapper in">
                    <bootstrap.Nav className="nav navbar-nav">
                        <bootstrap.NavItem data-toggle="aside" eventKey={1} href="#" onClick={() => {
                            if (document.getElementsByTagName("BODY")[0].classList.contains('aside-toggled')) {
                                document.getElementsByTagName("BODY")[0].classList.remove('aside-toggled');
                            } else {
                                document.getElementsByTagName("BODY")[0].classList.add('aside-toggled');
                            }
                        }}>
                            <em className="fa fa-align-left"></em>
                        </bootstrap.NavItem>
                        <bootstrap.NavItem eventKey={2} href="#" onClick={() => {
                            if (document.getElementById('search-pan').classList.contains('open')) {
                                document.getElementById('search-pan').classList.remove('open');
                            } else {
                                document.getElementById('search-pan').classList.add('open');
                            }
                        }}>
                            <em className="fa fa-search"></em>
                        </bootstrap.NavItem>
                    </bootstrap.Nav>
                    <bootstrap.Nav className="nav navbar-nav" pullRight>
                        <bootstrap.NavItem data-toggle="aside" eventKey={1} href="#" onClick={() => {
                            const { dispatch } = this.props;
                            dispatch(changeInvoiceView(true));
                        }}>
                            <em className="fa fa-th-list"></em>
                        </bootstrap.NavItem>
                        <bootstrap.NavItem data-toggle="aside" eventKey={1} href="#" onClick={() => {
                            const { dispatch } = this.props;
                            dispatch(changeInvoiceView(false));
                        }}>
                            <em className="fa fa-th-large"></em>
                        </bootstrap.NavItem>
                        <bootstrap.NavDropdown
                            className="dropdown-toggle dropdown"
                            eventKey={3}
                            title={<em className="fa fa-user"></em>}
                            id="basic-nav-dropdown"
                            open={activeDropdown === 'userDropdown'}
                            onClick={() => this.toggleDropdown('userDropdown')}
                            ref={this.node}
                        >
                            <bootstrap.MenuItem eventKey={3.2} onClick={this.logout}>Logout</bootstrap.MenuItem>
                        </bootstrap.NavDropdown>
                    </bootstrap.Nav>
                </bootstrap.Navbar.Collapse>
                <div role="search" id="search-pan" className="navbar-form">
                    <div className="form-group has-feedback">
                        <input id='txtSearch' type="text" placeholder="Type and hit Enter.." className="form-control" onKeyPress={this.searchHandleKeyPress} />
                        <div className="fa fa-times form-control-feedback" style={{ pointerEvents: 'painted' }}
                            onClick={() => {
                                this.clearSearch();
                                document.getElementById('txtSearch').value = '';
                                document.getElementById('search-pan').classList.remove('open');
                            }}
                        ></div>
                    </div>
                    <button type="button" className="hidden btn btn-default">Search</button>
                </div>
            </bootstrap.Navbar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        invoiceView: state.invoiceView,
        menuStatus: state.menuStatus
    }
};

export default withRouter(connect(mapStateToProps)(Header));
